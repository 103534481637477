/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import {
	ChakraProvider,
	Grid,
	GridItem,
	Text,
	Image,
	WrapItem,
	Center,
	Stack,
	HStack,
	useToast,
} from '@chakra-ui/react';

import { motion } from 'framer-motion';
import { CloseIcon } from '@chakra-ui/icons';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { SettingsContext } from '../../SettingsConfig';
import { UserContext } from '../../UserContext';

export default function TextPost({ content, sender, id }) {
	let clip = navigator.clipboard;
	const { showSender, showFrostedTeture } = useContext(SettingsContext);
	const { refresh, setRefresh } = useContext(UserContext);
	const [hover, setHover] = useState(false);
	const toast = useToast();
	const CLEAR_POST = gql`
		mutation ($postId: String!) {
			clearPost(postId: $postId)
		}
	`;
	const [clearPost, { loading, error, data }] = useMutation(CLEAR_POST);
	let bonus = hover ? 0 : 0;
	return (
		<WrapItem
			width='70vw'
			mb='0'
			bgColor={showFrostedTeture ? 'rgba(206, 247, 255,0.2)' : 'rgba(206, 247, 255,1)'}
			borderColor='#8EB5F4'
			boxShadow={'0px 0px 5px #8EB5F4'}
			// boxShadow={'0px 0px 100px 5px rgba(0,0,0,0.4)'}
			borderWidth='3px'
			borderRadius='10px'
			color={showFrostedTeture ? '#fff' : '#000'}
			textAlign='center'
			paddingTop={`${4 + +bonus}%`}
			paddingBottom={showSender ? `${1 + +bonus}%` : `${3 + +bonus}%`}
			fontSize='20px'
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}>
			<Stack width='100%'>
				<Center
					onClick={() => {
						toast({
							position: 'bottom',
							title: 'Copied to Clipboard!',
							description: 'Your item has been Copied to your Clipboard.',
							status: 'success',
							duration: 1500,
							isClosable: true,
						});
						clip.writeText(content);
					}}>
					<Text height='100%' width='100%' pl='8%' pr='8%' fontSize='lg' style={{ fontFamily: 'Inter' }}>
						{content}
					</Text>
				</Center>
				<div
					style={{
						marginLeft: '80%',
						marginRight: '20%',
						transform: 'scale(0.8)',
						position: 'absolute',
						justifyContent: 'right',
						verticalAlign: 'center',
						opacity: hover ? 1 : 0,
						transitionDelay: '0.5s',
						transition: 'opacity 0.5s ease-in-out',
					}}>
					<motion.div whileHover={{ scale: 1.3 }}>
						<CloseIcon
							onClick={() => {
								toast({
									position: 'bottom',
									title: 'Post Deleted',
									description: 'Your post has been deleted',
									status: 'error',
									duration: 1500,
									isClosable: true,
								});
								clearPost({ variables: { postId: id } });
								setRefresh(true);
							}}
						/>
					</motion.div>
				</div>

				<Text
					fontSize='sm'
					style={{
						justifyContent: 'right',
					}}
					width='100%'>
					{showSender ? `Sent By: ${sender}` : ''}
				</Text>
			</Stack>
		</WrapItem>
	);
}
