import React, { useState, useContext } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
	Image,
	HStack,
	WrapItem,
	useDisclosure,
	Text,
	Button,
	Heading,
	Stack,
	Center,
} from '@chakra-ui/react';
import audioIcon from '../../Images/AudioIcon.png';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { UserContext } from '../../UserContext';
import { motion } from 'framer-motion';
import ReactAudioPlayer from 'react-audio-player';
export default function AudioPost({ content, sender, id, type, name, extension }) {
	const { refresh, setRefresh, setSaved } = useContext(UserContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const CLEAR_POST = gql`
		mutation ($postId: String!) {
			clearPost(postId: $postId)
		}
	`;

	const download = (url, path, callback) => {
		var link = document.createElement('a');
		link.setAttribute('download', path);
		link.href = url;
		document.body.appendChild(link);
		link.click();
		link.remove();
		callback();
	};
	const [clearPost, { loading, error, data }] = useMutation(CLEAR_POST);
	// const popOver
	console.log(type);
	let rand = Math.random();
	return (
		<WrapItem>
			<Modal isOpen={isOpen} onClose={onClose} isCentered size='full'>
				<ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.8)' }} />
				<ModalCloseButton color='white'></ModalCloseButton>
				<ModalContent
					style={{
						width: '40%',
						marginTop: '40vh',
						// height: '0px',
						backgroundColor: 'rgba(0,0,0,0)',
					}}
					onClick={() => {
						onClose();
					}}>
					<Center>
						<ReactAudioPlayer src={content} controls style={{ marginTop: '20vh', width: '100%' }} />
					</Center>
					<Center>
						<Heading color='white'>{name}</Heading>
					</Center>
					<HStack width='100%'>
						<Text
							style={{ color: 'white' }}
							onClick={() => {
								window.open(content, '_blank').focus();
							}}
							width='50%'>
							Click to Open in Browser
						</Text>
						<Text
							width='50%'
							style={{
								color: 'white',
							}}
							textAlign='right'
							onClick={() => {
								window.open(content, '_blank').focus();
							}}>
							Sent By: {sender}
						</Text>
					</HStack>
					<Button
						colorScheme='green'
						onClick={async () => {
							let path = '';
							download(content, `${path}${name}`, () => {
								console.log('✅ Done!');
								localStorage.setItem(`${id}`, true);
							});
							setSaved(true);
							setTimeout(() => {
								setSaved(false);
							}, 5000);
						}}>
						Save
					</Button>
					<Button
						mt='1%'
						colorScheme='red'
						onClick={() => {
							clearPost({ variables: { postId: id } });
							setRefresh(true);
						}}>
						Delete
					</Button>
				</ModalContent>
			</Modal>
			<motion.div
				whileHover={{ scale: 1.1 }}
				animate={{ x: 0 }}
				initial={{ x: 100 * (rand > 0.5 ? 1 : -1) }}
				exit={{ opacity: 0 }}
				transition={{ duration: 1, ease: 'circOut' }}>
				<WrapItem height='30vh'>
					<Stack height='100%' fit='contain' align='center center'>
						<Image
							onClick={onOpen}
							src={audioIcon}
							borderRadius='20px'
							height='90%'
							fit='contain'
							align='center center'
						/>
						<Center>
							<Text>{name}</Text>
						</Center>
					</Stack>
					{/* </motion.div> */}
					{/* <img src={content} style={styles.image}></img> */}
				</WrapItem>
			</motion.div>
		</WrapItem>
	);
}
const styles = {
	image: {
		borderRadius: '20px',
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		maxWidth: '100%',
		maxHeight: '100%',
	},
};
