import React from 'react';
import { Heading, Stack, Text, Button, Image, HStack, Center } from '@chakra-ui/react';
import dumpus from '../Images/dumpus.webp';
import dumpusHead from '../Images/Relay Head.webp';
import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';
import StyledButton from './StyledButton';
export default function SplashNew() {
	let width = window.innerWidth;
	let height = window.innerHeight;

	return (
		<div>
			<HStack position='absolute' left='3vw' top='3vh' display={['none', 'flex']}></HStack>
			<HStack marginTop='20vh' height='100vh' zIndex={5}>
				<Stack
					width={['100%']}
					// textAlign='left'
					color={'white'}
					justifyItems={'center'}
					alignItems={'center'}
					spacing={0}
					mt={['20%', '0%']}>
					<motion.div
						className='Heading'
						transition={{ delay: 0.3 }}
						easing='easeInOut'
						initial={{ opacity: 0.0, y: -50 }}
						animate={{ opacity: 1, y: 0 }}
						style={{
							fontSize: '7vh',
							color: 'white',
							textAlign: 'center',
							lineHeight: '1',
							marginTop: '-10%',
							width: '85%',
						}}>
						Airdrop For <div style={{ color: '#8EB5F4', display: 'inline' }}>Everyone</div>
					</motion.div>
					<motion.div
						className='Subtext'
						transition={{ delay: 0.6 }}
						easing='easeInOut'
						initial={{ opacity: 0.0, y: -50 }}
						animate={{ opacity: 1, y: 0 }}
						style={{
							fontSize: '1.3rem',
							width: '80%',
							color: 'white',
							textAlign: 'center',
							marginTop: '0%',
						}}>
						Relay provides the most fluid way to share between all your devices
					</motion.div>
					<motion.div
						transition={{ delay: 1 }}
						style={{ width: '100%' }}
						easing='easeInOut'
						initial={{ opacity: 0.0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}>
						<Stack
							direction={['column', 'column', 'row', 'row']}
							w='100%'
							justifyContent={'center'}
							alignItems={'center'}
							spacing={[1, 2, 3]}
							mt={4}>
							<StyledButton
								mobile='none'
								priority={2}
								onClick={() => {
									window.open('/login');
								}}>
								Use Web Client
							</StyledButton>
							<StyledButton
								mobile='flex'
								priority={1}
								onClick={() => {
									let downloadText = document.getElementById('download');
									downloadText.scrollIntoView({
										behavior: 'smooth',
										block: 'center',
										inline: 'nearest',
									});
								}}>
								Download
							</StyledButton>
							<StyledButton
								mobile='none'
								priority={2}
								onClick={() => {
									window.open(
										'https://chromewebstore.google.com/detail/relay/hchahoobnhpbpiaebhjidfjijfkmnflo',
									);
								}}>
								Chrome Extension
							</StyledButton>
						</Stack>
					</motion.div>
					<motion.div
						transition={{ delay: 1.5 }}
						style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
						initial={{ opacity: 0.0, y: 200 }}
						animate={{ opacity: 1, y: 0 }}>
						<Center w='100%'>
							<Image
								src={dumpus}
								w={['70%', '40%', '30%', '18%']}
								margin='2%'
								scaleMode='crop'
								borderRadius={300}
								alignSelf={'center'}
							/>
						</Center>
					</motion.div>
				</Stack>
			</HStack>
		</div>
	);
}
