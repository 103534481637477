import React, { createContext, useState } from 'react';
export const UserContext = createContext({});
export const UserProvider = ({ children }) => {
	const [user, setUser] = useState({
		username: 'NOT LOADED',
	});
	const [token, setToken] = useState('');
	const [refresh, setRefresh] = useState(false);
	const [saved, setSaved] = useState(false);
	const [sent, setSent] = useState(false);

	const setUserTo = (newUser) => {
		console.log('setting the user');
		console.log('setting the ussssser1');
		if (typeof newUser == 'undefined') {
		} else {
			setUser(newUser);
		}
		console.log('setting the ussssser2');
	};

	const setAuthToken = (token) => {
		setToken(token);
	};

	return (
		<UserContext.Provider
			value={{
				user,
				setUserTo,
				token,
				setAuthToken,
				setRefresh,
				refresh,
				saved,
				setSaved,
				sent,
				setSent,
			}}>
			{children}
		</UserContext.Provider>
	);
};
