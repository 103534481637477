import React, { useContext, useState } from 'react';
import {
	ChakraProvider,
	Grid,
	GridItem,
	Text,
	Image,
	WrapItem,
	Center,
	Stack,
	HStack,
	useToast,
} from '@chakra-ui/react';
import { SettingsContext } from '../../SettingsConfig';
import { UserContext } from '../../UserContext';
import { CloseIcon } from '@chakra-ui/icons';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { motion } from 'framer-motion';

export default function URLPost({ content, sender, id }) {
	const { showSender, showFrostedTeture } = useContext(SettingsContext);
	const { refresh, setRefresh } = useContext(UserContext);
	const [hover, setHover] = useState(false);
	const toast = useToast();

	const CLEAR_POST = gql`
		mutation ($postId: String!) {
			clearPost(postId: $postId)
		}
	`;
	const [clearPost, { loading, error, data }] = useMutation(CLEAR_POST);
	let bonus = hover ? 0 : 0;
	return (
		<WrapItem
			width='70vw'
			mb='0'
			bgColor={showFrostedTeture ? 'rgba(255, 242, 204,0.2)' : 'rgba(255, 242, 204,1)'}
			borderColor='#ffd082'
			_hover={{ bgColor: '#fff2ac', color: '#000' }}
			borderWidth='3px'
			borderRadius='10px'
			color={showFrostedTeture ? '#fff' : '#000'}
			boxShadow={'0px 0px 5px #ffd082'}
			style={{
				transition: 'all 0.3s ease-in-out',
			}}
			textAlign='center'
			paddingTop={`${4 + +bonus}%`}
			paddingBottom={showSender ? `${1 + +bonus}%` : `${3 + +bonus}%`}
			fontSize='20px'
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}>
			<Stack width='100%'>
				<Center
					onClick={() => {
						toast({
							position: 'bottom',
							title: 'Opening Link.',
							description: 'Wait a moment, your link should open shortly.',
							status: 'success',
							duration: 5000,
							isClosable: true,
							mb: '10%',
						});
						window.open(content, '_blank').focus();
					}}>
					<Text height='100%' width='100%' ml='8%' mr='8%' fontSize='lg'>
						{content}
					</Text>
				</Center>
				<div
					style={{
						marginLeft: '80%',
						marginRight: '20%',
						transform: 'scale(0.8)',
						position: 'absolute',
						justifyContent: 'right',
						verticalAlign: 'center',
						opacity: hover ? 1 : 0,
						transitionDelay: '0.5s',
						transition: 'opacity 0.5s ease-in-out',
					}}>
					<motion.div whileHover={{ scale: 1.3 }}>
						<CloseIcon
							onClick={() => {
								clearPost({ variables: { postId: id } });
								setRefresh(true);
							}}
						/>
					</motion.div>
				</div>
				<Text
					fontSize='sm'
					style={{
						justifyContent: 'right',
					}}
					width='100%'>
					{showSender ? `Sent By: ${sender}` : ''}
				</Text>
			</Stack>
		</WrapItem>
	);
}
