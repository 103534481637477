import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Home';
import Navbar from '../Components/Navbar';
import Forgot from './Forgot';
import ReactGA from 'react-ga4';

import Login from './Login';
import Client from './Client';
import Settings from '../Components/Settings/Settings';
import { ErrorBoundary } from 'react-error-boundary';
import Dashboard from './Dashboard';

export default function AnimatedRoutes() {
	const location = useLocation();
	useEffect(() => {
		ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: 'Custom Title' });
	}, [location]);
	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				<Route path='/Home' element={<Home />} />
				<Route path='/' element={<Home />} />
				<Route path='/Login' element={<Login />} />
				<Route
					path='/Client'
					element={
						<ErrorBoundary fallback={<Login />}>
							<Client />
						</ErrorBoundary>
					}
				/>
				<Route path='/Settings' element={<Settings />} />
				<Route path='*' element={<Home />} />
				<Route path='/Forgot' element={<Forgot />} />
				<Route path='/Dashboard' element={<Dashboard />} />
			</Routes>
		</AnimatePresence>
	);
}
