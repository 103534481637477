import React, { useContext, useEffect, useState } from 'react';
import { colors } from '../../Colors';
import {
	Text,
	Wrap,
	WrapItem,
	Box,
	Stack,
	HStack,
	Input,
	Button,
	Center,
	Switch,
	Spacer,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	FormLabel,
	Progress,
	CircularProgress,
	CircularProgressLabel,
	useToast,
	Badge,
	Tabs,
	TabList,
	TabPanel,
	Tab,
	TabPanels,
	Divider,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { SettingsContext } from '../../SettingsConfig';
import { UserContext } from '../../UserContext';
import { redirect } from 'react-router-dom';
import { useMutation, gql, useQuery } from '@apollo/client';
import { Scrollbars } from 'react-custom-scrollbars';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	Image,
	PopoverArrow,
	PopoverCloseButton,
	Heading,
	AspectRatio,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

export default function Settings() {
	const toast = useToast();
	const {
		showSender,
		setSettingsShow,
		setShowSender,
		addToClipboard,
		setClip,
		name,
		setName,
		fileCompression,
		setfileCompression,
		fileCompressionAmount,
		setfileCompressionAmount,
		notifications,
		setNotifications,
		showQuickDrop,
		setQuickDrop,
		showFrostedTeture,
		setTexture,
	} = useContext(SettingsContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { setUserTo, user } = useContext(UserContext);
	const LOGOUT_USER = gql`
		mutation {
			logoutUser
		}
	`;
	const [logout] = useMutation(LOGOUT_USER);
	console.log(fileCompression);
	const [pass, setPass] = useState('');
	const [confirm, setConfirm] = useState('');
	useEffect(() => {
		// fetch the referral crap
	}, []);

	const [showMain, setShowMain] = useState(false);

	const GET_USER = gql`
		query {
			userByToken {
				_id
				username
				dyno
				referPoints
			}
		}
	`;
	const { loading, error, data, refetch } = useQuery(GET_USER);

	useEffect(() => {
		console.log('User from token result');
		console.log(data);
		if (data == null) {
			console.log('null returning');
			return;
		} else {
			try {
				console.log('data from token');
				console.log(data);
				console.log(data.userByToken);
				if (data.userByToken != null) {
					setUserTo(data.userByToken);
					console.log('Setting Show Main');
					setShowMain(true);
				} else {
					return redirect('/login');
				}
			} catch (error) {
				console.log(error);
				return redirect('/login');
			}
		}
	}, [data]);
	if (loading)
		return (
			<div style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,30)' }}>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1 }}
					style={{ width: '100vw', height: '100vh' }}>
					<Center style={{ height: '100vh' }}>
						<Heading color='white' size='xl'>
							Loading Settings...
						</Heading>
					</Center>
				</motion.div>
			</div>
		);
	if (error) return redirect('/login');
	if (!showMain) return <div>User not logged in</div>;

	return (
		<Scrollbars style={{ width: '100%', height: '100vh', backgroundColor: colors.background }}>
			<motion.div
				animate={{ opacity: 1 }}
				initial={{ opacity: 0 }}
				transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}>
				<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
					<ModalOverlay />
					<ModalContent bg={colors.background}>
						<ModalHeader textAlign='center' color='white'>
							<Heading>Delete Account</Heading>
						</ModalHeader>
						<ModalCloseButton color='white' />
						<ModalBody>
							<Center>
								<Text color='white'>Are you sure you want to delete your account? </Text>
							</Center>
							<Center mt='5%'>
								<Stack color='white' w='70%' pb='10%'>
									<FormLabel>Password</FormLabel>
									<Input
										placeholder='password'
										type='password'
										onChange={(text) => {
											setPass(text.currentTarget.value);
										}}></Input>
									<FormLabel aria-required isRequired>
										Confirm Password
									</FormLabel>
									<Input
										placeholder='confirm password'
										type='password'
										onChange={(text) => {
											setConfirm(text.currentTarget.value);
										}}></Input>
									{confirm != pass && confirm != '' && (
										<Text color='red'>Your Passwords Do Not Match</Text>
									)}
									<Button
										colorScheme={'red'}
										onClick={() => {
											if (pass != '' && pass == confirm) {
												setUserTo({ username: 'NOT LOADED' });
												try {
													logout();
												} catch (e) {
													console.log(e);
												}
												localStorage.setItem('accessToken', '');
												localStorage.setItem('refreshToken', '');

												window.location.href = '/login';
											}
										}}>
										Confirm
									</Button>
								</Stack>
							</Center>
						</ModalBody>
					</ModalContent>
				</Modal>
				<div>
					<Wrap
						spacing='30px'
						align='center'
						width='100%'
						bg={colors.background}
						color='#fff'
						height='100vh'
						mt='2vh'>
						<Tabs
							orientation='vertical'
							mt='0vh'
							w='70%'
							ml='0%'
							colorScheme='purple'
							borderLeft='1px solid rgba(255,255,255,0.0)'
							bg={colors.background}
							defaultIndex={1}>
							<TabList
								w='15vw'
								colorScheme='purple'
								h='200vh'
								backgroundColor={'rgba(0,0,25,0.5)'}
								pt='5vh'
								textAlign='left'
								// positon="absolute"
								position='fixed'>
								<Tab disabled></Tab>
								<Tab
									fontWeight={'bold'}
									fontSize={'xl'}
									padding='3vh'
									textAlign='left'
									_hover={{
										backgroundColor: 'rgba(255,255,255,0.1)',
										color: 'white',
									}}>
									General
								</Tab>
								<Tab
									fontWeight={'bold'}
									fontSize={'xl'}
									padding='3vh'
									textAlign='left'
									float='left'
									_hover={{
										backgroundColor: 'rgba(255,255,255,0.1)',
										color: 'white',
									}}>
									Dyno
								</Tab>
								<Tab
									fontWeight={'bold'}
									fontSize={'xl'}
									padding='3vh'
									textAlign='center'
									_hover={{
										backgroundColor: 'rgba(255,255,255,0.1)',
										color: 'white',
									}}>
									Quick Links
								</Tab>
								<Center>
									{/* <Divider
                  orientation="horizontal"
                  w="80%"
                  borderColor="rgba(55,50,155,0.8)"
                  borderWidth={1}
                /> */}
								</Center>
								<Tab
									fontWeight={'bold'}
									fontSize={'xl'}
									padding='3vh'
									textAlign='left'
									mt={['1vh', '1vh', '30vh']}
									onClick={() => {
										setSettingsShow(false);
										window.location.href = '/client';
									}}
									_hover={{
										backgroundColor: 'rgba(255,255,255,0.1)',
										color: 'white',
									}}>
									<Stack>
										<Text>Return</Text>
									</Stack>
								</Tab>
								<Tab>
									<Button
										variant='ghost'
										colorScheme='red'
										mt='10%'
										p='20%'
										fontSize={'xl'}
										onClick={() => {
											setSettingsShow(false);
											setUserTo({ username: 'NOT LOADED' });
											try {
												logout();
											} catch (e) {
												console.log(e);
											}
											localStorage.setItem('accessToken', '');
											localStorage.setItem('refreshToken', '');
											window.location.href = '/login';
										}}>
										<Text style={{ fontWeight: 'bold' }} size='2xl'>
											Logout
										</Text>
									</Button>
								</Tab>
							</TabList>
							<TabPanels ml='23%' width='20vw'>
								<div>
									<WrapItem>
										<Button
											variant='ghost'
											colorScheme='white'
											// w="10vw"
											mt='10vh'
											ml='0vw'
											textAlign='left'
											alignContent='left'
											padding='0'
											onClick={() => {
												setSettingsShow(false);
											}}></Button>
									</WrapItem>{' '}
									<WrapItem>
										<Heading
											// fontFamily="Inter"
											fontSize='50px'
											// fontWeight="extrabold"
											ml='0%'
											width='100vw'>
											Settings
											<Text fontSize='20px' fontWeight='light'>
												Signed in as {user.username}
											</Text>
										</Heading>
									</WrapItem>
								</div>
								<TabPanel w='100%'>
									<Heading p='1%' size='lg'>
										General Settings
									</Heading>
									<Box
										bg='rgba(255,255,255,0.1)'
										w='100%'
										h='10vh'
										borderWidth='2px'
										borderRadius='md'>
										<HStack h='100%' w='100%'>
											<Center h='100%'>
												<Text ml='10%' width='40vw' size='lg' fontSize='1.1em'>
													Automatically Copy New Drops
												</Text>
											</Center>
											<Spacer />
											<Switch
												w='10%'
												size='lg'
												onChange={(e) => {
													console.log(e);
													setClip(!addToClipboard);
													localStorage.setItem('autoCopy', !addToClipboard);
												}}
												isChecked={localStorage.getItem('autoCopy') || false}
											/>
										</HStack>
									</Box>

									<Box
										bg='rgba(255,255,255,0.1)'
										w='100%'
										h={`${localStorage.getItem('enableCompression') ? '17.5%' : '20%'}`}
										borderWidth='2px'
										borderRadius='md'>
										<Stack
											style={{
												verticalAlign: 'middle',
												transform: 'translateY(0vh)',
											}}>
											<HStack h='100%' w='100%'>
												<Center h='100%'>
													<Text
														ml='10%'
														width='40vw'
														size='lg'
														fontSize='1.1em'
														paddingTop='4%'
														paddingBottom='
                        4%'>
														Enable File Compression
														{localStorage.getItem('enableCompression') &&
															` (${localStorage.getItem('compressionNum')})`}
														<Popover color='black'>
															<PopoverTrigger>
																<InfoOutlineIcon ml='2%' />
															</PopoverTrigger>
															<PopoverContent>
																<PopoverArrow />
																<PopoverCloseButton />
																<PopoverHeader color='black'>Whats this?</PopoverHeader>
																<PopoverBody color='black'>
																	Enabling file compression will make all file uploads
																	from this device compressed, which means they will
																	become smaller, faster, and more convenient.
																	However, this is only possible by lowering the
																	quality of the file. If quality is not your concern,
																	then it is reccomended to enable file compression.
																</PopoverBody>
															</PopoverContent>
														</Popover>
													</Text>
												</Center>
												<Spacer />
												<Switch
													w='10%'
													size='lg'
													onChange={(e) => {
														console.log(e);
														setfileCompression(!fileCompression);
														localStorage.setItem('enableCompression', !fileCompression);
													}}
													isChecked={localStorage.getItem('enableCompression') || false}
												/>
											</HStack>
											<Center>
												{localStorage.getItem('enableCompression') && (
													<Slider
														paddingBottom='5%'
														value={localStorage.getItem('compressionNum')}
														min={0}
														max={1}
														step={0.1}
														onChange={(num) => {
															setfileCompressionAmount(num);
															localStorage.setItem('compressionNum', num);
														}}
														w='86%'>
														<SliderTrack bg='white'>
															<Box position='relative' right={10} />
															<SliderFilledTrack bg='lightBlue' />
														</SliderTrack>
														<SliderThumb boxSize={4} />
													</Slider>
												)}
											</Center>
										</Stack>
									</Box>
									<Box
										bg='rgba(255,255,255,0.1)'
										w='100%'
										h='10vh'
										borderWidth='2px'
										borderRadius='md'>
										<HStack h='100%' w='100%'>
											<Center h='100%'>
												<Text ml='10%' width='40vw' size='lg' fontSize='1.1em'>
													Show Post Sender
												</Text>
											</Center>
											<Spacer />
											<Switch
												w='10%'
												size='lg'
												onChange={(e) => {
													console.log(e);
													setShowSender(!showSender);
													localStorage.setItem('showSender', !showSender);
												}}
												isChecked={localStorage.getItem('showSender') || false}
											/>
										</HStack>
									</Box>
									<Box
										bg='rgba(255,255,255,0.1)'
										w='100%'
										h='10vh'
										borderWidth='2px'
										borderRadius='md'>
										<HStack h='100%' w='100%'>
											<Center h='100%'>
												<Text ml='10%' width='40vw' size='lg' fontSize='1.1em'>
													Notifications
												</Text>
											</Center>
											<Spacer />
											<Switch
												w='10%'
												size='lg'
												onChange={(e) => {
													console.log(e);
													setNotifications(!notifications);
													localStorage.setItem('enableNotifications', !notifications);
												}}
												isChecked={localStorage.getItem('enableNotifications') || false}
											/>
										</HStack>
									</Box>
									<Box
										bg='rgba(255,255,255,0.1)'
										w='100%'
										h='10vh'
										borderWidth='2px'
										borderRadius='md'>
										<HStack h='100%' w='100%'>
											<Center h='100%'>
												<Text ml='10%' width='40vw' size='lg' fontSize='1.1em'>
													Show Quick Drop Box
												</Text>
											</Center>
											<Spacer />
											<Switch
												w='10%'
												size='lg'
												onChange={(e) => {
													console.log(e);
													setQuickDrop(!showQuickDrop);
													localStorage.setItem('setQuickDrop', !showQuickDrop);
												}}
												isChecked={localStorage.getItem('setQuickDrop') || false}
											/>
										</HStack>
									</Box>
									<Box
										bg='rgba(255,255,255,0.1)'
										w='100%'
										h='10vh'
										borderWidth='2px'
										borderRadius='md'>
										<HStack h='100%' w='100%'>
											<Center h='100%'>
												<Text ml='10%' width='40vw' size='lg' fontSize='1.1em'>
													Show Frosted Glass Textures
												</Text>
											</Center>
											<Spacer />
											<Switch
												w='10%'
												size='lg'
												onChange={(e) => {
													console.log(e);
													setTexture(!showFrostedTeture);
													localStorage.setItem('setFrostedTexture', !showFrostedTeture);
												}}
												isChecked={localStorage.getItem('setFrostedTexture') || false}
											/>
										</HStack>
									</Box>

									<Button
										variant='outline'
										colorScheme='red'
										w='100%'
										p='2%'
										onClick={() => {
											onOpen();
										}}>
										Delete Account
									</Button>
								</TabPanel>
								<TabPanel w='100%'>
									<Heading pl='2%' pt='3%' pb='3%' w='100%'>
										Dyno{' '}
										<Badge colorScheme='green' fontSize='0.5em'>
											New
										</Badge>
									</Heading>
									<Center>
										{!user.dyno && (
											<motion.div whileHover={{ scale: 1.1 }}>
												<Image
													src={
														'https://cdn.discordapp.com/attachments/760776202121117706/1046191501539020931/New_Projectadsdas.png'
													}
													h='23vh'
													w='74vh'
												/>
											</motion.div>
										)}
									</Center>
									<Center>
										<HStack>
											<CircularProgress
												value={(user.referPoints / 3) * 100}
												size='120px'
												thickness={'5px'}>
												<CircularProgressLabel>
													{((user.referPoints / 3) * 100).toFixed(0)}%
												</CircularProgressLabel>
											</CircularProgress>
											<div style={{ padding: '5%' }}>
												<Heading size='md'>Invite & Earn Free Dyno</Heading>
												<Text>
													When 3 friends sign up using your referral code, you will earn 30
													days of Dyno at no charge at all!
												</Text>
												<Text fontSize={'sm'}>
													Note: Your Account is unverified and will not recieve points
													<Button variant={'ghost'} colorScheme='purple' size='sm'>
														Verify Now
													</Button>
												</Text>
												<Heading display={'inline'} size='sm' mr='2%' mt='15%'>
													Your Referral Code:
												</Heading>
												<Button
													variant={'outline'}
													mt='1%'
													onClick={() => {
														navigator.clipboard.writeText(user._id);
														toast({
															status: 'success',
															title: 'Copied to Clipboard',
															description: 'Copied Referral Code to Clipboard',
														});
													}}>
													{user._id}
												</Button>
											</div>
										</HStack>
									</Center>
								</TabPanel>
								<TabPanel>
									<Heading pl='2%' pt='3%' pb='3%' w='100%'>
										Quick Links
									</Heading>
									<Stack>
										<Button
											variant='outline'
											p='2%'
											onClick={() => {
												window.open(
													'https://beakplusemi.notion.site/Terms-Service-bdbb2e6f0ae54b49acbd840c7eb91ff4',
													'_blank',
												);
											}}>
											Privacy and Terms
										</Button>
										<Button
											variant='outline'
											p='2%'
											onClick={() => {
												window.open(
													'https://beakplusemi.notion.site/Help-e71c1a82560f40beabc5d80bec6ddc9a',
													'_blank',
												);
											}}>
											Help
										</Button>
										<Center p='5%' />
									</Stack>
								</TabPanel>
								<TabPanel>
									<Button
										onClick={() => {
											window.location.href = '/client';
										}}></Button>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Wrap>
				</div>
			</motion.div>
		</Scrollbars>
	);
}
