import React from 'react';

import { Button } from '@chakra-ui/react';
export default function StyledButton({ children, ...props }) {
	console.log(props);
	const [isHover, setIsHover] = React.useState(false);
	const hoverStyle = {
		backgroundColor: 'rgba(17,28,96,0.3)',
		backgroundOpacity: '0.5',
		boxShadow: '0px 0px 20px 1px #111C60',
		transform: 'scale(1.05)',
	};
	return (
		<Button
			onClick={props.onClick}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			// width={['40%', '30%', '15%', '12 %
			paddingX={['20%', '15%', '6%', '3%']}
			display={[props.mobile, props.mobile, 'flex', 'flex']}
			style={{
				backgroundColor: 'rgba(17,28,96,0.1)',
				backgroundOpacity: '0.3',
				fontFamily: 'Helvetica-Light',
				borderRadius: '15px',
				color: 'white',
				borderColor: '#111c60',
				borderWidth: 2,
				borderStyle: 'solid',
				height: '50px',
				boxShadow: '0px 0px 20px 2px #111C60',
				align: 'center',
				transition: 'all 0.3s ease-in-out',
				...(isHover && hoverStyle),
			}}>
			{children}
		</Button>
	);
}
