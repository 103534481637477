import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { UserContext } from '../UserContext';
import { redirect, useNavigate } from 'react-router-dom';
import {
	Flex,
	Spacer,
	Box,
	Center,
	Image,
	Input,
	Stack,
	Button,
	Text,
	Heading,
	HStack,
	Checkbox,
} from '@chakra-ui/react';
import banner from '../Images/RelayBannerOfficial.png';
import config from '../environment';

const styles = {
	inputLabel: {
		marginTop: '2%',
		paddingLeft: '4vw',
		color: 'rgba(0,0,50,1)',
		fontWeight: '400',
		// textAlign: 'center',
		width: '100%',
	},
	error: {
		marginTop: '5%',
		paddingLeft: '3vw',
		color: 'red',
	},
	input: {
		backgroundColor: 'rgba(20,0,255,0.1)',
		borderRadius: '10px',
		borderWidth: 1,
		padding: '20px',
		borderBottomWidth: 3,
		borderColor: 'rgba(100,100,255,0.6)',
		fontWeight: '400',
		marginLeft: '3vw',
		marginTop: '0px',
		transition: 'opacity 2s',
	},
};

export default function Login() {
	const navigate = useNavigate();
	const { user, setUserTo, setAuthToken } = useContext(UserContext);

	const goToClient = () => {
		window.open('/client', '_self');
	};

	const LOG_USER = gql`
		mutation ($password: String!, $username: String!) {
			loginUser(password: $password, username: $username) {
				success
				code
				message
				data {
					_id
					username
					dyno
					referPoints
					verified
				}
				refreshToken
				accessToken
			}
		}
	`;
	const PING = gql`
		mutation {
			ping
		}
	`;
	const CREATE_USER = gql`
		mutation ($password: String!, $username: String!, $email: String, $phone: String, $refer: String) {
			createUser(password: $password, username: $username, email: $email, phone: $phone, refer: $refer) {
				success
				code
				message
				data {
					_id
					username
					dyno
					referPoints
					verified
				}
				accessToken
				refreshToken
			}
		}
	`;
	const CREATE_POST = gql`
		mutation ($type: String!, $content: String!, $sender: String!, $userId: String!) {
			createPost(type: $type, content: $content, sender: $sender, userId: $userId) {
				success
				message
			}
		}
	`;
	const [createPost] = useMutation(CREATE_POST);
	const [createUser] = useMutation(CREATE_USER);
	const [pingServer] = useMutation(PING);
	const [logUser, { loading, error, data }] = useMutation(LOG_USER);
	const [loginMode, setLoginMode] = useState('Login');
	const [inputUsername, setUsername] = useState('');
	const [inputEmail, setEmail] = useState('');
	const [inputPhone, setPhone] = useState('');
	const [inputPassword, setPassword] = useState('');
	const [inputConfirm, setConfirm] = useState('');
	const [inputRefer, setRefer] = useState('');
	const [errorText, setErrorText] = useState('');
	const [loginEnable, setLoginEnable] = useState(true);
	const [connected, setConnected] = useState(false);
	const [toggleHover, setToggleHover] = useState(false);
	const [showPass, setShowPass] = useState(false);
	const [bannerOpacity, setBannerOpacity] = useState(0.9);

	// useEffect(async () => {
	// 	let response = await pingServer();
	// 	response.data.ping == 'pong' ? setConnected(true) : setConnected(false);
	// }, []);

	const handleUsernameChange = (event) => {
		setUsername(event.target.value);
	};
	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};
	const handlePhoneChange = (event) => {
		setPhone(event.target.value);
	};
	const handleReferChange = (event) => {
		setRefer(event.target.value);
	};
	const handleConfirmChange = (event) => {
		setConfirm(event.target.value);
		if (event.target.value != inputPassword) {
			setErrorText('Passwords do not Match');
		} else {
			setErrorText('');
		}
	};
	const toggleLoginMode = () => {
		if (loginMode == 'Login') {
			setLoginMode('Sign Up');
		} else {
			setLoginMode('Login');
		}
	};
	const loginUser = async () => {
		let res;
		if (loginMode == 'Login') {
			setLoginEnable(false);
			console.log(inputPassword);
			console.log(inputUsername);
			res = logUser({
				variables: { username: inputUsername, password: inputPassword },
			})
				.then((result) => {
					console.log(result);
					setLoginEnable(true);
					res = result.data.loginUser;
					setErrorText(res.message);
					if (res.success) {
						setUserTo(res.data);
						localStorage.setItem('accessToken', res.accessToken);
						localStorage.setItem('refreshToken', res.refreshToken);
						goToClient();
					}
				})
				.catch((err) => {
					console.log(err);
					setLoginEnable(true);
					setErrorText('There was an error. Please check your credentials and try again.');
					// console.warn(err);
				});
		} else {
			if (inputConfirm != inputPassword) return;
			setLoginEnable(false);
			// sign login
			res = await createUser({
				variables: {
					username: inputUsername,
					password: inputPassword,
					email: inputEmail,
					phone: inputPhone,
				},
			})
				.then((result) => {
					res = result.data.createUser;
					console.log(res);
					setErrorText(res.message);
					setLoginEnable(true);
					if (!res.success) {
						console.log('Unsuccessful Login');
						setErrorText(res.message);
						console.log(res.message);
						return;
					}

					setUserTo(res.data);
					localStorage.setItem('accessToken', res.accessToken);
					localStorage.setItem('refreshToken', res.refreshToken);
					setTimeout(() => {
						createPost({
							variables: {
								type: 'url',
								content: 'https://relayapp.gg/',
								sender: 'The Relay Team',
								userId: res.data._id,
							},
						});
						createPost({
							variables: {
								type: 'text',
								content: 'Learn more about relay at the link below!',
								sender: 'The Relay Team',
								userId: res.data._id,
							},
						});
						createPost({
							variables: {
								type: 'text',
								content:
									'This is your drop feed. Every drop you send will be shown here and will stay here for 1 hour.',
								sender: 'The Relay Team',
								userId: res.data._id,
							},
						});
						createPost({
							variables: {
								type: 'text',
								content: 'Welcome to Relay!',
								sender: 'The Relay Team',
								userId: res.data._id,
							},
						});
					}, 3000);
					goToClient();
				})
				.catch((err) => {
					console.log('errored');
					setLoginEnable(true);
					setErrorText(err);
					console.log(err);
				});
		}
		console.log('res:::');
		console.log(res);
	};
	return (
		<div style={{ overflow: 'hidden' }}>
			<Flex w='100vw' h='100vh' color='black' bgColor={'black'} overflowY={'hidden'} overflowX={'hidden'}>
				<Stack
					w={loginMode == 'Login' ? ['100vw', '50vw', '35vw'] : ['100vw', '50vw', '45vw']}
					bg='white'
					style={{ transition: 'width 0.5s' }}
					borderRightRadius={'5vw'}
					zIndex={3}
					boxShadow={'0px 0px 30px 10px rgba(0,0,0,0.4)'}>
					<div style={{ marginTop: '20vh' }}></div>
					<Heading style={{ marginLeft: '3vw' }}>{loginMode}</Heading>
					{!config.prod && (
						<div>
							<Text>Development Mode </Text>
						</div>
					)}
					{connected && !config.prod && (
						<div>
							<Text>Connected to Server </Text>
						</div>
					)}
					<div
						colorScheme='purple'
						variant='link'
						align='left'
						onMouseEnter={() => {
							setToggleHover(true);
						}}
						onMouseLeave={() => {
							setToggleHover(false);
						}}
						style={{
							marginLeft: '3vw',
							width: '25%',
							textAlign: 'center',
							fontWeight: '700',
							color: 'rgba(130,70,220,1)',
							cursor: 'pointer',
							transition: '0.2s',
							transitionProperty: 'transform',
							transitionTimingFunction: 'ease-in-out',
							transform: toggleHover ? 'scale(1.15)' : 'scale(1)',
						}}
						onClick={toggleLoginMode}>
						<div style={{ alignContent: 'left', float: 'left', textAlign: 'left' }}>
							{loginMode == 'Login' ? 'Sign Up' : 'Login'}
						</div>
					</div>
					{/* <div style={{ marginTop: '5vh' }}></div> */}

					<Heading size='sm' colorScheme='red' style={styles.error}>
						{errorText}
					</Heading>
					<h1 style={styles.inputLabel}>
						Username <div style={{ color: 'red', fontWeight: '700', display: 'inline' }}>*</div>
					</h1>
					<Input
						style={styles.input}
						size='md'
						colorScheme='messenger'
						width='75%'
						ml='5%'
						pl='5%'
						marginLeft='10%'
						onFocus={() => {
							setBannerOpacity(0.6);
						}}
						onBlur={() => {
							setBannerOpacity(1);
						}}
						onChange={handleUsernameChange}></Input>
					<h1 style={styles.inputLabel}>
						Password <div style={{ color: 'red', fontWeight: '700', display: 'inline' }}>*</div>
					</h1>
					<Input
						style={styles.input}
						size='md'
						colorScheme='messenger'
						type={showPass ? 'text' : 'password'}
						width='75%'
						ml='5%'
						pl='5%'
						marginLeft='10%'
						onFocus={() => {
							setBannerOpacity(0.6);
						}}
						onBlur={() => {
							setBannerOpacity(1);
						}}
						onChange={handlePasswordChange}></Input>
					<Checkbox
						style={styles.inputLabel}
						colorScheme='purple'
						onChange={() => {
							setShowPass(!showPass);
						}}>
						Show Password
					</Checkbox>
					{loginMode == 'Sign Up' && (
						<Stack direction={'row'} w='30vw'>
							<div>
								<h1 style={styles.inputLabel} w='100%'>
									Confirm Password{' '}
									<div
										style={{
											color: 'red',
											fontWeight: '700',
											display: 'inline',
										}}>
										*
									</div>
								</h1>
								<Input
									style={styles.input}
									size='md'
									colorScheme='messenger'
									type='password'
									width='100%'
									ml='5%'
									pl='5%'
									onFocus={() => {
										setBannerOpacity(0.6);
									}}
									onBlur={() => {
										setBannerOpacity(1);
									}}
									marginLeft='10%'
									onChange={handleConfirmChange}></Input>
							</div>
							<div>
								<h1 style={{ ...styles.inputLabel, width: '100%' }}>Referral Code</h1>
								<Input
									style={styles.input}
									size='md'
									colorScheme='messenger'
									width='100%'
									ml='5%'
									pl='5%'
									onFocus={() => {
										setBannerOpacity(0.6);
									}}
									onBlur={() => {
										setBannerOpacity(1);
									}}
									marginLeft='1%'
									onChange={handleReferChange}></Input>
							</div>
						</Stack>
					)}
					{loginMode == 'Sign Up' && (
						<Stack direction={'row'} w='30vw'>
							<div>
								<h1 style={styles.inputLabel}>
									Email{' '}
									<div
										style={{
											color: 'red',
											fontWeight: '700',
											display: 'inline',
										}}>
										*
									</div>
								</h1>
								<Input
									style={styles.input}
									size='md'
									colorScheme='messenger'
									width='100%'
									ml='5%'
									onFocus={() => {
										setBannerOpacity(0.6);
									}}
									onBlur={() => {
										setBannerOpacity(1);
									}}
									pl='5%'
									marginLeft='10%'
									onChange={handleEmailChange}></Input>
							</div>
							<div>
								<h1 style={styles.inputLabel}>
									Phone{' '}
									<div
										style={{
											color: 'red',
											fontWeight: '700',
											display: 'inline',
										}}>
										*
									</div>
								</h1>
								<Input
									style={styles.input}
									size='md'
									colorScheme='messenger'
									onFocus={() => {
										setBannerOpacity(0.6);
									}}
									onBlur={() => {
										setBannerOpacity(1);
									}}
									width='100%'
									ml='5%'
									pl='5%'
									marginLeft='1%'
									onChange={handlePhoneChange}></Input>
							</div>
						</Stack>
					)}
					<Center w='95%'>
						<Button
							size='md'
							style={{
								width: '80%',
								marginTop: '1%',
								borderBottomColor: 'rgba(10,10,100,0.9)',
								borderBottomWidth: 2,
							}}
							onClick={loginUser}
							colorScheme='purple'
							isLoading={!loginEnable}>
							{loginMode}
						</Button>
					</Center>
					{loginMode == 'Login' && (
						<Button
							colorScheme='purple'
							variant='link'
							onClick={() => {
								window.open('https://relayapp.gg/forgot');
							}}>
							Forgot Password
						</Button>
					)}
					<Text padding={'1%'} position={'absolute'} bottom={0} color='rgba(50,0,50,0.2)'>
						Relay v1.2.1
					</Text>
				</Stack>
				<Center w={['0vw', '55vw', '75vw']} style={{ WebkitAppRegion: 'drag', transition: 'width 1s' }}>
					<Image
						src={banner}
						style={{
							height: '100vh',
							width: '100vw',
							objectFit: 'cover',
							objectPosition: 'bottom',
							position: 'absolute',
							zIndex: 0,
							userDrag: 'none',
							userSelect: 'none',
							draggable: 'false',
							opacity: bannerOpacity,
							pointerEvents: 'none',
							transition: 'opacity 1s',
						}}></Image>
				</Center>
			</Flex>
		</div>
	);
}
