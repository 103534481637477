import React from 'react';
import { Heading, Stack, Text, Button, Center, HStack, Image, Box, Kbd, background } from '@chakra-ui/react';
import pc from '../Images/PC ss.webp';
import phone from '../Images/IOS SS.webp';
import arrowRight from '../Images/Arrow Right.webp';
import arrowLeft from '../Images/Arrow Left.webp';
import link from '../Images/Link.webp';
import anywhere from '../Images/anywhere.webp';
import fast from '../Images/fast.webp';
import apple from '../Images/Apple.webp';
import android from '../Images/Android.webp';
import linux from '../Images/Linux.png';
import windows from '../Images/Window.png';
import limitless from '../Images/limitless.webp';
import mark from '../Images/mark.webp';
import ava from '../Images/ava.webp';
import ep from '../Images/ep.webp';
import { useToast } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ash from '../Images/ash.webp';
import Quote from './Quote';
import ReactGA from 'react-ga4';
import securey from '../Images/secuery.png';
import instanty from '../Images/instanty.png';
import ContentBar from './ContentBar';

// import { sendToast } from '../../public/demo';
export default function Content() {
	const toast = useToast();
	return (
		<div>
			<motion.div
				transition={{ delay: 0.5 }}
				initial={{ opacity: 0, y: 200 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}>
				<Center w='100%'>
					<Heading
						color='white'
						size='2xl'
						w='60%'
						textAlign={'center'}
						mt='20vh'
						// marginLeft='10%'
					>
						<div style={{ color: '#8EB5F4', display: 'inline' }}>Upgrade</div> Your Workflow
					</Heading>
				</Center>
				<Center>
					<Text color='white' fontWeight={300} fontSize='xl'>
						using Relay’s Quick and Simple Design
					</Text>
				</Center>
			</motion.div>
			<Center mt='5vh'>
				{/* <Stack
					w={['80%', '100%']}
					marginLeft={['0%', '10%']}
					mt='5vh'
					direction={['column', 'row']}
					align='center'>
					<motion.div
						transition={{ delay: 0.5 }}
						initial={{ opacity: 0, y: 200 }}
						whileInView={{ opacity: 1, y: 0 }}
						viewport={{ once: true }}
						style={{ width: window.innerWidth < 600 ? '100%' : '50%' }}>
						<Image src={pc} />
					</motion.div>
					<Stack w={['40%', '10%']} justifyContent={'center'}>
						{window.innerWidth > 600 && (
							<motion.div
								transition={{ delay: 1, duration: 0.2 }}
								initial={{ opacity: 0, x: -200 }}
								whileInView={{ opacity: 1, x: 0 }}
								viewport={{ once: true }}>
								<Image src={arrowRight} />
							</motion.div>
						)}
						<motion.div
							transition={{ delay: 1.25 }}
							initial={{ opacity: 0, x: 0 }}
							whileInView={{ opacity: 1, x: 0 }}
							viewport={{ once: true }}>
							<Center w='100%'>
								<Image src={link} />
							</Center>
						</motion.div>
						{window.innerWidth > 600 && (
							<motion.div
								transition={{ delay: 1.5, duration: 0.2 }}
								initial={{ opacity: 0, x: 200 }}
								whileInView={{ opacity: 1, x: 0 }}
								viewport={{ once: true }}>
								<Image src={arrowLeft} />
							</motion.div>
						)}
					</Stack>
					<motion.div
						transition={{ delay: 0.6 }}
						initial={{ opacity: 0, y: 200 }}
						whileInView={{ opacity: 1, y: 0 }}
						style={{ width: window.innerWidth < 600 ? '60%' : '21%' }}
						viewport={{ once: true }}>
						<Image src={phone} />
					</motion.div>
				</Stack> */}
				<iframe
					style={{
						boxShadow: '0 4px 24px 3px rgba(0,0,0,0.4)',
						zIndex: 99,
					}}
					width={Math.min(window.innerWidth - 100, 500)}
					height='315'
					src='https://www.youtube.com/embed/WGPdNRXGthU?si=o-DqURmf6iT9YLz2'
					title='YouTube video player'
					frameborder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
					allowfullscreen></iframe>
			</Center>

			<div
				style={{
					backgroundColor: 'rgba(0,0,0,0.1)',
					paddingTop: '12vh',
					paddingBottom: '12vh',
					marginTop: '20vh',
				}}>
				<Heading color='white' size='2xl' w='100%' textAlign={'center'} mt='2vh' mb='8vh'>
					{/* Why Relay? */}
				</Heading>
				<Stack spacing={0}>
					<ContentBar
						picture={anywhere}
						name={'Anything, Anywhere'}
						color={'255,87,87'}
						text={
							'We know the pain of working with different operating systems. Thats why Relay breaks the barrier between incompatible devices.'
						}
					/>
					<ContentBar
						picture={limitless}
						name={'Without Limits'}
						color={'87,87,255'}
						right={true}
						text={
							'Relay allows you to share data regardless of distance, operating system, and without compression. Relay doesn’t hold you back.'
						}
					/>
					<ContentBar
						picture={securey}
						name={'Secure By Default'}
						color={'87,255,255'}
						text={
							'Relay is encrypted and deletes all data after 6 hours. We don’t store any data on our servers and nothing is tied to your personally identifiable information.'
						}
					/>
					<ContentBar
						picture={instanty}
						name={'Zero Click Transfers'}
						color={'87,87,255'}
						right={true}
						text={
							'Using Shortcuts (alt + shift + V) you can send content to your phone without even opening the app.'
						}
					/>
					<ContentBar
						picture={fast}
						name={'Send & Save Seamlessly'}
						color={'87,255,87'}
						text={
							'Relay let you save time and effort by temporarily storing data in the cloud in one click. Use an interface that was designed to share data with yourself.'
						}
					/>
				</Stack>
			</div>
			{window.innerWidth > 6000 && (
				<div>
					<Heading color='white' size='2xl' w={['80%', '60%']} textAlign={'left'} mt='30vh' marginLeft='10%'>
						<motion.div
							transition={{ delay: 0.5 }}
							initial={{ opacity: 0, x: window.innerWidth < 600 ? -200 : -400 }}
							whileInView={{ opacity: 1, x: 0 }}
							viewport={{ once: true }}>
							Dont believe us? <div style={{ color: '#FF5757', display: 'inline' }}>Try it.</div>
						</motion.div>
					</Heading>

					<motion.div
						transition={{ delay: 0.75 }}
						initial={{ opacity: 0, x: window.innerWidth < 600 ? -200 : -400 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}>
						<Text color='white' marginLeft='10%' fontSize={['lg', 'xl']} w='75%'>
							{window.innerWidth >= 600
								? 'Drag the picture icons to the Upload Box to send it to your phone'
								: 'Click the clipboard icon on the phone to send it to your computer'}
						</Text>
					</motion.div>
					{window.innerWidth > 600 ? (
						<motion.div
							transition={{ delay: 1 }}
							initial={{ opacity: 0, scale: 0 }}
							whileInView={{ opacity: 1, scale: 1 }}
							viewport={{ once: true }}>
							<div id='demoCanvas'></div>
						</motion.div>
					) : (
						<div id='demoCanvasMobile'></div>
					)}
				</div>
			)}
			<Heading
				color='white'
				size='2xl'
				w='60%'
				textAlign={'left'}
				mt={window.innerWidth > 600 ? '0vh' : '10vh'}
				marginLeft='10%'>
				Hear it from
				<div style={{ color: '#A5E9FF', display: 'inline' }}> Them</div>
			</Heading>

			<Stack marginLeft={'10%'} textAlign={'left'} mt='4%' direction={['column', 'row']}>
				<Quote
					picture={mark}
					name={'Mark Dailey'}
					text={`As a videographer, Relay makes transferring band videos
				from my phone to my computer so simple!`}
				/>
				<Quote
					picture={ep}
					name={'Sahil Patil'}
					text={`As a photographer for Robbinsville High School, The Leukemia and Lymphoma Society, and The Future Business Leaders of America, Relay makes sharing images from my desktop to my phone a breeze saving me hours at a time. I’m not sure what I would do without such an amazing platform`}
				/>
				<Quote
					picture={ava}
					name={'Ava Rossi'}
					text={`Relay helped me conveniently send, and save tons of pictures at once from my Desktop to my iPhone while before I had to individual save each picture. Huge time saver.`}
				/>
				<Quote
					picture={ash}
					name={'Ashley Fitzsimmons'}
					text={`Relay's ability to work in the background & automatically copy messages has been a huge game changer.`}
				/>
			</Stack>
			{/* <Heading color='white' size='2xl' w='60%' textAlign={'left'} mt='20vh' marginLeft='10%' id='download'>
				Web Client
			</Heading>
			<Text color='white' marginLeft='10%' fontSize={['lg', 'xl']} w='75%'>
				Relay has officially released on web allowing you to access everything, anywhere.
			</Text>
			<Center>
				<Image src={require('../Images/login.webp')} width={'50vw'} borderRadius={10} dropShadow={10}></Image>
			</Center> */}
			<Heading color='white' size='2xl' w='60%' textAlign={'left'} mt='20vh' marginLeft='10%' id='download'>
				Download
			</Heading>
			<Text color='white' marginLeft='10%' fontSize={['md', 'lg']} w='75%'>
				Relay currently natively supports Android, iOS, Windows, and Linux. If you dont see your platform
				listed, feel free to use our web client!
			</Text>
			<Center>
				<br />
			</Center>
			<Stack direction={['column', 'row']} w='100%' justifyContent={'center'} mt='2%' gap={['2%', '3%']}>
				<Button
					variant={'ghost'}
					onClick={() => {
						ReactGA.event({
							category: 'Button Click',
							action: 'Clicked on Android Download button',
							label: 'Downloads',
						});
						console.log('bruh');
						window.open('https://play.google.com/store/apps/details?id=com.suntex.relay&pli=1', '_blank');
					}}
					borderRadius={['15px', '15px']}
					backgroundColor={'rgba(25,0,200,0.03)'}
					width={['75%', '18%']}
					alignSelf={['center', 'flex-start']}
					boxShadow={'0 4px 15px 1px rgba(0,0,0,0.4)'}
					hover={{
						backgroundColor: 'rgba(255,255,255,0.7)',
					}}
					height={['30%', '100%']}>
					<Image padding={['25%', '25%']} src={android} />
				</Button>
				<Button
					variant={'ghost'}
					onClick={() => {
						ReactGA.event({
							category: 'Button Click',
							action: 'Clicked on Apple Download button',
							label: 'Downloads',
						});
						if (
							/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
						) {
							// true for mobile device
							window.open('https://apps.apple.com/us/app/relay-sharing/id1579123469', '_blank');
						} else {
							// false for not mobile device
							console.log('bruh');
							window.open('https://apps.apple.com/us/app/relay-sharing/id1579123469', '_blank');
						}
					}}
					width={['80%', '18%']}
					alignSelf={['center', 'flex-start']}
					backgroundColor={'rgba(25,0,200,0.03)'}
					boxShadow={'0 4px 24px 3px rgba(0,0,0,0.4)'}
					borderRadius={['15px', '15px']}
					hover={{
						backgroundColor: 'rgba(255,255,255,0.7)',
					}}
					height={['30%', '100%']}>
					<Image padding={['25%', '25%']} src={apple} />
				</Button>

				<Button
					variant={'ghost'}
					onClick={() => {
						ReactGA.event({
							category: 'Button Click',
							action: 'Clicked on Windows Download button',
							label: 'Downloads',
						});
						console.log('bruh');
						window.open('ms-windows-store://pdp/?ProductId=9NQHVXM7QXW6', '_blank');
					}}
					width={['80%', '18%']}
					alignSelf={['center', 'flex-start']}
					backgroundColor={'rgba(25,0,200,0.03)'}
					boxShadow={'0 4px 24px 3px rgba(0,0,0,0.4)'}
					borderRadius={['15px', '15px']}
					hover={{
						backgroundColor: 'rgba(255,255,255,0.7)',
					}}
					height={['30%', '100%']}>
					<Image padding={['25%', '25%']} src={windows} />
				</Button>
				<Button
					variant={'ghost'}
					onClick={() => {
						console.log('bruh');
						ReactGA.event({
							category: 'Button Click',
							action: 'Clicked on Linux Download button',
							label: 'Downloads',
						});
						// window.open('https://apps.apple.com/us/app/relay-sharing/id1579123469', '_blank');
						const downloadURL =
							'https://randowharton.s3.us-east-2.amazonaws.com/Relay-1.2.1-arm64.AppImage';
						const link = document.createElement('a');
						link.href = downloadURL;
						link.download = 'Relay-1.2.1-arm64.AppImage'; // specify the filename
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}}
					width={['80%', '18%']}
					alignSelf={['center', 'flex-start']}
					boxShadow={'0 4px 24px 3px rgba(0,0,0,0.4)'}
					backgroundColor={'rgba(25,0,200,0.03)'}
					borderRadius={['15px', '15px']}
					hover={{
						backgroundColor: 'rgba(255,255,255,1)',
					}}
					height={['30%', '100%']}>
					<Image padding={['25%', '25%']} src={linux} />
				</Button>
			</Stack>
			<Box
				borderWidth='0px'
				borderColor={'white'}
				borderRadius='10px'
				borderStyle={'solid'}
				padding={['5vw', '2vw']}
				boxShadow={'0 4px 24px 3px rgba(0,0,0,0.4)'}
				backgroundColor={'rgba(25,0,200,0.03)'}
				textAlign={['center', 'left']}
				marginLeft={'10%'}
				w={['80%', '80%']}
				mt='4%'>
				<Text color='white' fontWeight={500} fontSize='xl' w={['100%', '100%']}>
					Join other creators, tech enthusiasts, or students in reinventing how devices interconnect
				</Text>
			</Box>
			<Center h='20vh'></Center>
		</div>
	);
}
