import React from 'react';
import { Heading, Stack, Text, Button, Center, HStack, Image, Box } from '@chakra-ui/react';

export default function Quote({ picture, name, text }) {
	return (
		<Box
			bg={'rgba(0,0,25,1)'}
			borderColor='rgba(09,00,60,0.7)'
			borderStyle={'solid'}
			borderWidth={'4px'}
			borderRadius='10px'
			zIndex={5}
			// padding={['5%', '1%']}
			w={['90%', '20vw']}
			h={['90%', '30%']}
			boxShadow={'0 5px 20px 4px rgba(0,0,0,0.4)'}>
			<Center
				position='absolute'
				zIndex={2}
				style={{
					backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 25, 1))',
				}}
				w={[window.innerWidth * 0.8 - 4, window.innerWidth * 0.2 - 8]}
				h={[window.innerWidth * 0.8, 0.2 * window.innerWidth + 2]}
			/>
			<Image src={picture} w={['80vw', '20vw']} h={['80vw', '20vw']} borderRadius={6} />

			<Heading size='md' color='white' textAlign={'center'} zIndex={30}>
				{name}
			</Heading>
			<Text paddingBottom='10%' color='white' bg='rgba(0,0,25,1)' padding='5%' textAlign={'center'}>
				{text}
			</Text>
		</Box>
	);
}
