import React, { useContext, useState, useEffect } from 'react';
import ClipboardIcon from './ClipboardIcon';
import ImageIcon from './ImageIcon';
import TextIcon from './TextIcon';
import { Center, Grid, GridItem, Text, Image, Stack, StackDivider, Heading } from '@chakra-ui/react';
import RelayLogo from '../../Images/LOGO_TEMP.webp';
import { redirect } from 'react-router-dom';
import { SettingsContext } from '../../SettingsConfig';
import { UserContext } from '../../UserContext';
import './styles.css';

import { motion } from 'framer-motion';

export default function Sidebar() {
	const { setSettingsShow } = useContext(SettingsContext);
	const { user } = useContext(UserContext);
	const [hovered, setHover] = useState(false);
	const [height, setHeight] = useState(window.innerHeight);
	useEffect(() => {
		function handleResize() {
			setHeight(window.innerHeight);
			console.log('size change');
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<GridItem
			w='120px'
			style={{
				background: '#000019',
				borderRadius: '20px',
				boxShadow: '0px 0px 100px 1px rgba(0, 0, 0, 0.6)',
				// borderRight: '3px solid #3A3AEF24',
				zIndex: 100,
				padding: '10px',
			}}
			height='100vh'>
			<Stack
				spacing='20px'
				align='center'
				divider={<StackDivider borderColor='#060029' />}
				height='85%'
				style={{}}>
				<motion.div whileHover={{ scale: 1.1 }}>
					{/* <Image src={require('../../Images/Relay Head.webp')} style={{ width: '70%' }}></Image> */}
				</motion.div>
				<motion.div whileHover={{ scale: 1.1 }}>
					<ClipboardIcon />
				</motion.div>
				<motion.div whileHover={{ scale: 1.1 }}>
					<ImageIcon />
				</motion.div>
				<motion.div whileHover={{ scale: 1.1 }}>
					<TextIcon />
				</motion.div>
			</Stack>

			<motion.div whileHover={{ rotate: 60, scale: 1.05 }}>
				<Center>
					<Image
						src='https://cdn-icons-png.flaticon.com/512/126/126472.png'
						style={{ width: '60%', height: '60%', filter: 'invert(1)' }}
						//transform={hovered ? `rotate(${180}deg)` : `rotate(0deg)`}
						onHover={() => {
							setHover(true);
						}}
						onMouseLeave={() => {
							setHover(false);
						}}
						onClick={() => {
							// const { ipcRenderer } = require('electron');
							// ipcRenderer.send('logout', true);
							console.log('CLICKED SETTINGS');
							setSettingsShow(true);
							window.location.href = '/Settings';
							// return redirect('/Settings');
						}}
						className='icon'
					/>
				</Center>
			</motion.div>
		</GridItem>
	);
}
