const isProduction = true;
const productionConfig = {
	server: 'https://relayapp.gg',
	// server: 'http://52.15.82.36',
	port: '3000',
	socketPort: '4000',
	prod: true,
};
const devConfig = {
	// server: 'http://18.189.194.54', // Prod Server
	// server: 'http://192.168.1.87', // Bene
	// server: 'https://169.254.144.26', // Bene 2
	// server: 'http://172.20.10.2', // Hotspot
	socketPort: '4000',
	// server: 'http://10.110.166.68', // Dorm
	server: 'http://10.110.3.193', // Snell
	// server: 'https://192.168.86.34', // House
	// server: 'http://10.129.199.243', // Capital One
	port: '3000',
	prod: false,
};

const config = isProduction ? productionConfig : devConfig;
export default config;
