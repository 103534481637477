import React, { useState, useContext } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
	Image,
	HStack,
	WrapItem,
	useDisclosure,
	Text,
	Button,
	Heading,
	Stack,
	Center,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { SettingsContext } from '../../SettingsConfig';
import { UserContext } from '../../UserContext';
import vidicon from '../../Images/vidicon.png';
import { motion } from 'framer-motion';
export default function VideoPost({ content, sender, id, type, name, extension }) {
	const { refresh, setRefresh, setSaved } = useContext(UserContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const CLEAR_POST = gql`
		mutation ($postId: String!) {
			clearPost(postId: $postId)
		}
	`;
	const download = (url, path, callback) => {
		// request.head(url, (err, res, body) => {
		// 	request(url).pipe(fs.createWriteStream(path)).on('close', callback);
		// });
	};
	const [clearPost, { loading, error, data }] = useMutation(CLEAR_POST);
	// const popOver
	console.log(type);
	let rand = Math.random();
	return (
		<WrapItem>
			<Modal isOpen={isOpen} onClose={onClose} isCentered size='full'>
				<ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.8)' }} />
				<ModalCloseButton></ModalCloseButton>
				<ModalContent
					style={{
						width: '40%',
						marginTop: '40vh',
						// height: '0px',
						backgroundColor: 'rgba(0,0,0,0)',
					}}
					onClick={() => {
						onClose();
					}}>
					<iframe
						src={content}
						alt='demo'
						title={name}
						onClick={() => {
							window.open(content, '_blank').focus();
						}}
						style={{ marginBottom: '0%', height: '50vh', width: '100%' }}
						fit='contain'
						width='100%'
						mb='0%'
					/>
					<Center>
						<Heading color='white'>{name}</Heading>
					</Center>
					<HStack width='100%'>
						<Text
							style={{ color: 'white' }}
							onClick={() => {
								window.open(content, '_blank').focus();
							}}
							width='50%'>
							Click to Open in Browser
						</Text>
						<Text
							width='50%'
							style={{
								color: 'white',
							}}
							textAlign='right'
							onClick={() => {
								window.open(content, '_blank').focus();
							}}>
							Sent By: {sender}
						</Text>
					</HStack>
					<Button
						colorScheme='green'
						onClick={async () => {
							let path = '';
							download(content, `${path}/${name}`, () => {
								console.log('✅ Done!');
								// const Store = require('electron-store');
								// const store = new Store();
								// localStorage.setItem(`${id}`, true);
							});
							setSaved(true);
							setTimeout(() => {
								setSaved(false);
							}, 5000);
						}}>
						Save
					</Button>
					<Button
						mt='1%'
						colorScheme='red'
						onClick={() => {
							clearPost({ variables: { postId: id } });
							setRefresh(true);
						}}>
						Delete
					</Button>
				</ModalContent>
			</Modal>
			<motion.div
				whileHover={{ scale: 1.1 }}
				animate={{ x: 0 }}
				initial={{ x: 100 * (rand > 0.5 ? 1 : -1) }}
				exit={{ opacity: 0 }}
				transition={{ duration: 1, ease: 'circOut' }}>
				<WrapItem>
					<Stack height='100%' fit='contain' align='center center' w='15vw'>
						<div
							onClick={onOpen}
							style={{
								borderRadius: '20px',
								backgroundColor: 'rgba(0,0,255,0.05)',
								border: '3px solid rgba(10,0,200,0.5)',
								textAlign: 'center',
								alignItems: 'center',
								justifyContent: 'center',
								// padding: '10%',
								flex: '1',
								width: '100%',
							}}>
							<Image
								src={vidicon}
								padding='30%'
								style={{
									filter: 'invert(100%)',
								}}
							/>
						</div>
						<Text w='100%'>{name}</Text>
					</Stack>
					{/* </motion.div> */}
					{/* <img src={content} style={styles.image}></img> */}
				</WrapItem>
			</motion.div>
		</WrapItem>
	);
}
const styles = {
	image: {
		borderRadius: '20px',
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		maxWidth: '100%',
		maxHeight: '100%',
	},
};
