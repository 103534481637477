import React, { useContext, useState } from 'react';
import {
	Image,
	Center,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	Stack,
	Heading,
} from '@chakra-ui/react';
import { useMutation, gql } from '@apollo/client';
import Dropzone from 'react-dropzone';
import S3 from 'react-aws-s3';
import { UserContext } from '../UserContext';
import { SettingsContext } from '../SettingsConfig';
import Compressor from 'compressorjs';
window.Buffer = window.Buffer || require('buffer').Buffer;

export default function DropUpload(props) {
	const { user, setSent } = useContext(UserContext);
	const { fileSending, setFileSending } = useContext(SettingsContext);

	const [files, setFiles] = useState([]);
	const CREATE_POST = gql`
		mutation (
			$type: String!
			$content: String!
			$sender: String!
			$userId: String!
			$name: String
			$extension: String!
		) {
			createPost(
				type: $type
				content: $content
				sender: $sender
				userId: $userId
				name: $name
				extension: $extension
			) {
				success
				message
			}
		}
	`;
	const [createPost] = useMutation(CREATE_POST);

	const sendPost = (acceptedFiles) => {
		if (acceptedFiles.length <= 0) return;
		const config = {
			bucketName: 'relay-file-upload',
			region: 'us-east-2',
			accessKeyId: 'AKIAZ7OW6KENMSPV5FRF',
			secretAccessKey: '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',
		};
		const ReactS3Client = new S3(config);
		console.log(acceptedFiles);

		// if (localStorage.getItem('enableCompression')) {
		// 	console.log('compressing');
		// 	acceptedFiles.map((file) => {});
		// }
		acceptedFiles.forEach((file) => {
			console.log(file);
			let name = Math.random().toString(36).substring(2, 15) + file.name;
			let nameNoSpaces = name.replaceAll(' ', '');
			console.log('name');
			let currentFile = file;
			new Compressor(file, {
				quality: 1,
				success: (result) => {
					console.log('COMPRESSED!');
					console.log(result);
					currentFile = result;
					ReactS3Client.uploadFile(currentFile, nameNoSpaces).then((data) => {
						console.log(data);
						let fileType = file.type;
						let [type, extension] = fileType.split('/');
						type = type == '' ? 'misc' : type;
						extension =
							extension == undefined ? file.name.substring(file.name.lastIndexOf('.')) : extension;
						console.log(`file type: ${type} big: ${file.type}`);
						console.log(type);
						console.log(file.name);
						console.log(extension);
						createPost({
							variables: {
								type: type,
								content: data.location,
								sender: 'Browser',
								userId: user._id,
								name: file.name,
								extension: extension,
							},
						}).then((res) => {
							setSent(true);
							setTimeout(() => setSent(false), 2000);
						});
						setFileSending(fileSending.filter((element) => element.name != file.name));
						setFiles([]);
					});
				},
				error: (err) => {
					console.log('ERROR COMPRESSING');
					console.log(err);
					ReactS3Client.uploadFile(currentFile, nameNoSpaces).then((data) => {
						console.log(data);
						let fileType = file.type;
						let [type, extension] = fileType.split('/');
						type = type == '' ? 'misc' : type;
						extension =
							extension == undefined ? file.name.substring(file.name.lastIndexOf('.')) : extension;
						console.log(`file type: ${type} big: ${file.type}`);
						console.log(type);
						console.log(file.name);
						console.log(extension);
						createPost({
							variables: {
								type: type,
								content: data.location,
								sender: 'Browser',
								userId: user._id,
								name: file.name,
								extension: extension,
							},
						}).then((res) => {
							setSent(true);
							setTimeout(() => setSent(false), 2000);
						});
						setFileSending(fileSending.filter((element) => element.name != file.name));
						setFiles([]);
					});
				},
			});
			setFileSending(
				fileSending.concat([
					{
						name: currentFile.name,
						size: (currentFile.size / (1024 * 1024)).toFixed(2),
					},
				]),
			);
		});
	};
	console.log(props.width);
	return (
		<Center>
			<Dropzone onDrop={(acceptedFiles) => sendPost(acceptedFiles)}>
				{({ getRootProps, getInputProps }) => (
					<section>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<Center w='100%' flexDirection='column'>
								<Heading color='white' w='100%' mt='2%' textAlign={'center'}></Heading>
								<Text
									width={props.width + 'vw'}
									padding='10vh'
									border='2px'
									backgroundColor={'rgba(25,60,265,0.05)'}
									borderColor={'rgba(255,255,255,0.3)'}
									ml={props.left + '%'}
									borderRadius='20px'
									mt='2vh'>
									<Center color='white' fontWeight={300}>
										Drag or Click to Upload Files
									</Center>
								</Text>
							</Center>

							<Stack>
								{files.map((file) => (
									<Text border='1px' borderRadius='5px' padding='2%' mt='3%'>
										{file.name}
									</Text>
								))}
							</Stack>
						</div>
					</section>
				)}
			</Dropzone>
		</Center>
	);
}
