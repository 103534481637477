import { Table, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

export default function Dashboard() {
	return (
		<div
			style={{
				backgroundColor: 'rgba(255,255,255,1)',
				color: 'black',
				padding: '10px',
				borderRadius: '10px',
				fontWeight: 'bold',
				width: '100vw',
				height: '100vh',
				fontSize: '2rem',
			}}>
			<div
				style={{
					backgroundColor: 'rgba(0,0,50,1)',
					top: '0px',
					zIndex: 10,
					position: 'absolute',
					backdropFilter: 'auto',
					backdropBlur: '6px',
					color: 'white',
					width: '100vw',
					left: '0px',
					padding: '2vh',
				}}>
				Dashboard
			</div>

			<div
				style={{
					flex: 1,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '10vh',
					gap: '10px',
				}}>
				<div>
					<h2>Recent Downloads</h2>
					<Table>
						<Thead>
							<Tr>
								<Th>Name</Th>
								<Th>Date</Th>
								<Th>Activity</Th>
							</Tr>
						</Thead>
					</Table>
				</div>
				<div>
					<h2>Stats</h2>
				</div>
			</div>
		</div>
	);
}
