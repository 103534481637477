import React, { useContext, useState } from 'react';

import {
	Image,
	Center,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	Stack,
} from '@chakra-ui/react';
import imgIcon from '../../Images/PhotoPicker.png';
import { useMutation, gql } from '@apollo/client';
import Dropzone from 'react-dropzone';
import S3 from 'react-aws-s3';
import DropUpload from '../DropUpload';
import { UserContext } from '../../UserContext';
import { Tooltip } from '@chakra-ui/react';
export default function ImageIcon() {
	const { user } = useContext(UserContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const CREATE_POST = gql`
		mutation ($type: String!, $content: String!, $sender: String!, $userId: String!) {
			createPost(type: $type, content: $content, sender: $sender, userId: $userId) {
				success
				message
			}
		}
	`;
	const [createPost] = useMutation(CREATE_POST);

	return (
		<div>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.8)' }} />
				<ModalContent style={{ backgroundColor: 'rgba(0,0,42)' }}>
					<ModalCloseButton color='white' />
					<ModalBody mt='0%' mb='5%'>
						<DropUpload />
					</ModalBody>
				</ModalContent>
			</Modal>
			<Tooltip label='Send a file' placement='right' fontSize='sm' openDelay={500} size='md'>
				<Center>
					<Image borderRadius='full' boxSize='70%' src={imgIcon} alt='Segun Adebayo' onClick={onOpen} />
				</Center>
			</Tooltip>
		</div>
	);
}
