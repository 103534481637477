import React, { useContext, useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import {
	Image,
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Textarea,
	Button,
	Input,
} from '@chakra-ui/react';
import { UserContext } from '../../UserContext';
import { Tooltip } from '@chakra-ui/react';
import textIcon from '../../Images/texter.png';
let validUrl = require('valid-url');
export default function TextIcon() {
	const { user, setSent } = useContext(UserContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [text, setText] = useState('');

	const inputHandle = (event) => {
		setText(event.target.value);
	};

	const CREATE_POST = gql`
		mutation ($type: String!, $content: String!, $sender: String!, $userId: String!) {
			createPost(type: $type, content: $content, sender: $sender, userId: $userId) {
				success
				message
			}
		}
	`;
	const [createPost] = useMutation(CREATE_POST);

	const sendPost = () => {
		let type = validUrl.isUri(text) ? 'url' : 'text';
		console.log(text);
		createPost({
			variables: {
				type: type,
				content: text,
				sender: 'Browser',
				userId: user._id,
			},
		})
			.then((res) => {
				setSent(true);
				setTimeout(() => setSent(false), 2000);
			})
			.catch((e) => {
				console.log(e);
			});
		onClose();
	};
	return (
		<div>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.7)' }} />
				<ModalContent style={{ backgroundColor: 'rgba(9,0,50,1)' }}>
					<ModalHeader color='white' padding='5%' paddingTop='10%'>
						Text Message
					</ModalHeader>
					<ModalCloseButton color='white'></ModalCloseButton>
					<ModalBody>
						<Textarea
							variant='flushed'
							placeholder='Message'
							onChange={inputHandle}
							style={{ color: 'white' }}
							borderColor='rgba(255,255,255,0.1)'
							borderWidth={2}
							borderRadius={10}
							padding='5%'
						/>
					</ModalBody>

					<ModalFooter>
						<Button
							mr={3}
							onClick={sendPost}
							borderColor='rgba(30,10,100,0.8)'
							borderRadius={5}
							borderWidth={2}
							style={{ backgroundColor: 'rgba(30,0,50,0.2)', color: ' white' }}>
							Send
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Tooltip label='Send a text message' placement='right' fontSize='sm' openDelay={500} size='md'>
				<Center>
					<Image borderRadius='full' boxSize='70%' src={textIcon} alt='Segun Adebayo' onClick={onOpen} />
				</Center>
			</Tooltip>
		</div>
	);
}
