import React, { createContext, useState } from 'react';

import { Redirect } from 'react-router-dom';

export const SettingsContext = createContext({});
export const SettingsProvider = ({ children }) => {
	const [show, setSettingsShow] = useState(false);
	const [showSender, setShowSender] = useState(false);
	const [addToClipboard, setClip] = useState(true);
	const [name, setName] = useState(false);
	const [spamVal, setSpamVal] = useState(0);
	const [hitSpam, setHitSpam] = useState(0);
	const [fileSending, setFileSending] = useState([]);
	const [spamGuard, setSpamGuard] = useState(false);
	const [fileCompression, setfileCompression] = useState(false);
	const [notifications, setNotifications] = useState(true);
	const [showQuickDrop, setQuickDrop] = useState(false);
	const [showFrostedTeture, setTexture] = useState(true);
	const [fileCompressionAmount, setfileCompressionAmount] = useState(0);
	return (
		<SettingsContext.Provider
			value={{
				show,
				showSender,
				setSettingsShow,
				setShowSender,
				addToClipboard,
				setClip,
				name,
				setName,
				spamVal,
				setSpamVal,
				hitSpam,
				fileSending,
				setFileSending,
				setHitSpam,
				fileCompression,
				setfileCompression,
				fileCompressionAmount,
				setfileCompressionAmount,
				setNotifications,
				notifications,
				showQuickDrop,
				setQuickDrop,
				showFrostedTeture,
				setTexture,
			}}>
			{children}
		</SettingsContext.Provider>
	);
};
