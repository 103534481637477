import React, { useState, useContext } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
	Image,
	HStack,
	WrapItem,
	useDisclosure,
	Text,
	Button,
	Heading,
	Stack,
	Box,
	Center,
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { UserContext } from '../../UserContext';
import { motion } from 'framer-motion';
import fileIcon from '../../Images/FileIcon.png';
export default function FilePost({ content, sender, id, type, name, extension }) {
	const { refresh, setRefresh, setSaved } = useContext(UserContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const CLEAR_POST = gql`
		mutation ($postId: String!) {
			clearPost(postId: $postId)
		}
	`;

	const download = (url, path, callback) => {
		// request.head(url, (err, res, body) => {
		// 	request(url).pipe(fs.createWriteStream(path)).on('close', callback);
		// });
	};
	const [clearPost, { loading, error, data }] = useMutation(CLEAR_POST);
	// const popOver
	console.log(type);
	let rand = Math.random();
	return (
		<WrapItem>
			<Modal isOpen={isOpen} onClose={onClose} isCentered h='50vh'>
				<ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.8)' }} />
				<ModalCloseButton color='white'></ModalCloseButton>
				<ModalContent
					style={{
						width: '40%',
						height: '20vh',
						marginTop: '40vh',
						backgroundColor: 'rgba(0,0,0,0)',
					}}
					onClick={() => {
						onClose();
					}}>
					<div
						style={{
							borderRadius: '20px',
							backgroundColor: 'rgba(0,0,255,0.05)',
							border: '3px solid rgba(10,0,200,0.5)',
							// height: '40vh',
							justifyContent: 'center',
							textAlign: 'center',
							padding: '10%',
							flex: 1,
							width: '100%',
						}}
						onClick={() => {
							window.open(content, '_blank').focus();
						}}>
						<Heading color='white' justifyContent={'center'}>
							{name}
						</Heading>
					</div>
					<Center></Center>
					<HStack width='100%'>
						<Text
							style={{ color: 'white' }}
							onClick={() => {
								window.open(content, '_blank').focus();
							}}
							width='50%'>
							Click to Open in Browser
						</Text>
						<Text
							width='50%'
							style={{
								color: 'white',
							}}
							textAlign='right'
							onClick={() => {
								window.open(content, '_blank').focus();
							}}>
							Sent By: {sender}
						</Text>
					</HStack>
					{/* <a href={content} download> */}
					<Button
						colorScheme='green'
						padding='2vh'
						onClick={async () => {
							window.location.assign(content);
							localStorage.setItem(`${id}`, true);
						}}>
						Save
					</Button>
					{/* </a> */}
					<Button
						mt='1%'
						padding='2vh'
						colorScheme='red'
						onClick={() => {
							clearPost({ variables: { postId: id } });
							setRefresh(true);
						}}>
						Delete
					</Button>
				</ModalContent>
			</Modal>
			<motion.div
				whileHover={{ scale: 1.1 }}
				animate={{ x: 0 }}
				initial={{ x: 100 * (rand > 0.5 ? 1 : -1) }}
				exit={{ opacity: 0 }}
				transition={{ duration: 1, ease: 'circOut' }}>
				<WrapItem>
					<Stack height='100%' fit='contain' align='center center'>
						{/* <Image
							onClick={onOpen}
							src={fileIcon}
							borderRadius='20px'
							height='90%'
							fit='contain'
							align='center center'
						/> */}
						<div
							onClick={onOpen}
							style={{
								borderRadius: '20px',
								backgroundColor: 'rgba(0,0,255,0.05)',
								border: '3px solid rgba(10,0,200,0.5)',
								padding: '5% 10%',
								textAlign: 'center',
								alignItems: 'center',
								justifyContent: 'center',
								flex: '1',
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}>
							<Heading
								size='md'
								verticalAlign={'middle'}
								alignSelf={'center'}
								w='full'
								paddingX='10%'
								display={'flex'}
								alignItems={'center'}
								justifyContent={'center'}
								color='white'
								fontWeight='bold'>
								File Drop
							</Heading>
							<Text w='full' px='10%' textAlign='center' h='100%' verticalAlign={'middle'}>
								{name}
							</Text>
						</div>
					</Stack>
					{/* </motion.div> */}
					{/* <img src={content} style={styles.image}></img> */}
				</WrapItem>
			</motion.div>
		</WrapItem>
	);
}
const styles = {
	image: {
		borderRadius: '20px',
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		maxWidth: '100%',
		maxHeight: '100%',
	},
};
