export const colors = {
	background: '#000019',
	text: '#fff',
	offwhite: '#FCFAF0',
	primary: '#8EB5F4',
	darkBlue: '#071677',
	secondary: '#3A3AEF',
	tertiary: '#FFD700',
	success: '#94EF8F',
	info: '#94EF8F',
	accent: '#E638F9',
	muted: '#FFD700',
	warning: '#FFD700',
	error: '#FF5757',
};
