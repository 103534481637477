import React, { useState } from 'react';
import Body from '../Components/Body/Body';
import Sidebar from '../Components/Sidebar/Sidebar';
import { Center, Grid, Heading } from '@chakra-ui/react';
import { useContext } from 'react';
import { SettingsContext } from '../SettingsConfig';
import { Scrollbars } from 'react-custom-scrollbars';
import Settings from '../Components/Settings/Settings';
import { redirect } from 'react-router-dom';
import { UserContext } from '../UserContext';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { colors } from '../Colors';

export default function Client() {
	const { show } = useContext(SettingsContext);
	const [showMain, setShowMain] = useState(false);
	const [attempts, setAttempts] = useState(0);

	const { user, setUserTo } = useContext(UserContext);
	const GET_USER = gql`
		query {
			userByToken {
				_id
				username
				dyno
				referPoints
			}
		}
	`;
	const { loading, error, data, refetch } = useQuery(GET_USER);

	useEffect(() => {
		console.log('loading: ' + loading);
		if (!loading && data == null) {
			window.open(window.location.href.replace('/client', '/login'), '_self');
		}
	}, [loading, data]);

	useEffect(() => {
		console.log('User from token result');
		console.log(data);
		setAttempts(attempts + 1);
		// setTimeout(() => {
		// 	if (data == null) {
		// 		window.open(window.location.href.replace('/client', '/login'), '_self');
		// 	}
		// }, 4000);
		console.log('login attempt: ' + attempts);
		if (data == null) {
			console.log('null returning');
			return;
		} else {
			try {
				if (data.userByToken != null) {
					setUserTo(data.userByToken);
					setShowMain(true);
				} else {
					return redirect('/login');
				}
			} catch (error) {
				console.log(error);
				return redirect('/login');
			}
		}
	}, [data]);
	if (loading)
		return (
			<div style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,30)' }}>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 1, delay: 0.5 }}
					style={{ width: '100vw', height: '100vh' }}>
					<Center style={{ height: '100vh' }}>
						<Heading color='white' size='lg'>
							Loading your posts...
						</Heading>
					</Center>
				</motion.div>
			</div>
		);
	if (error) return redirect('/login');
	if (!showMain) return <div style={{ color: 'white', fontSize: '20rem' }}>User not logged in</div>;
	return (
		<div
			style={{
				backgroundColor: colors.background,
				overflowX: 'none',
				overflowY: 'none',
			}}>
			<Grid
				h='100vh'
				templateRows='repeat(1, 1fr)'
				templateColumns='repeat(2, 1fr)'
				style={{ backgroundColor: colors.background }}>
				{show ? null : (
					<Sidebar
						style={{
							borderRight: '1px solid #ffffff',
							boxShadow: '20px 20px 244px rgba(355, 0, 355, 1)',
						}}
					/>
				)}
				<Scrollbars style={{ width: '100vw', height: '100vh' }}>{show ? <Settings /> : <Body />}</Scrollbars>
			</Grid>
		</div>
	);
}
