import React, { useState } from 'react';
import {
	Heading,
	Center,
	Text,
	Stack,
	Image,
	HStack,
	Button,
	Box,
	Input,
	useToast,
	PinInput,
	PinInputField,
} from '@chakra-ui/react';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { colors } from '../Colors';
import { Redirect } from 'react-router-dom';
import { motion } from 'framer-motion';
export default function Forgot() {
	const toast = useToast();
	// Define mutation
	const SEND_OTP = gql`
		mutation ($email: String!) {
			generateOTP(email: $email) {
				success
				message
			}
		}
	`;
	const RESET_PASS = gql`
		mutation ($id: String!, $password: String!) {
			resetPassword(id: $id, password: $password) {
				success
				message
			}
		}
	`;
	const VERIFY_CODE = gql`
		mutation ($code: Int!) {
			verifyCode(code: $code) {
				success
				message
				id
			}
		}
	`;
	const [sendOTP] = useMutation(SEND_OTP);
	const [resetPass] = useMutation(RESET_PASS);
	const [verifyCode] = useMutation(VERIFY_CODE);
	const [input, setInput] = useState('');
	const [pass, setPass] = useState('');
	const [verifyPass, setVerifyPass] = useState('');
	const [code, setCode] = useState('');
	const [fullCode, setFullCode] = useState(false);
	const [codeSent, setCodeSent] = useState(false);
	const [validCode, setValidCode] = useState(false);
	const [userId, setUserId] = useState('');
	const [passwordReset, setPasswordReset] = useState(false);
	const elementWidth = ['70%', '60%', '50%'];
	const pinWidth = ['11.67%', '10%', '8.33%'];
	return (
		<Box w='100vw' h='100%' minHeight={'100vh'} bg={colors.background} color='white'>
			{!passwordReset && (
				<motion.div
					initial={{ opacity: 0 }}
					transition={{ duration: 0.5, staggerChildren: 1, delayChildren: 0.5 }}
					whileInView={{ opacity: 1 }}
					viewport={{ once: true }}
					style={{ width: '100%', justifyContent: 'center' }}>
					<Center>
						<Heading
							textAlign='center'
							alignSelf={'center'}
							pt='10%'
							w={['80%', '50%']}
							size='3xl'
							mt={['30%', '8%']}
							color='white'
							lineHeight={[0.9, 0.7, 0.6]}>
							Forgot Password
						</Heading>
					</Center>
					<Center>
						<Text mt='2%' w={['80%', '50%']} textAlign={'center'}>
							Forgot your password? Dont worry we can reset it.
						</Text>
					</Center>
					<Stack>
						<motion.div
							initial={{ opacity: 0 }}
							transition={{ duration: 0.5, staggerChildren: 0.5, delayChildren: 0.5 }}
							whileInView={{ opacity: 1 }}
							viewport={{ once: true }}>
							<motion.Center>
								<Heading mt={['20%', '10%', '5%']}>Email Recovery</Heading>
							</motion.Center>
							<motion.Center>
								<Input
									placeholder='Email'
									p='1%'
									w={elementWidth}
									borderBottom={'3px solid #d0d0d0'}
									textAlign='center'
									value={input}
									onChange={(event) => {
										setInput(event.target.value);
									}}
								/>
							</motion.Center>
							<motion.Center>
								<Button
									w={elementWidth}
									colorScheme='purple'
									borderBottom={'3px solid'}
									colorMode='purple'
									variant={'outline'}
									onClick={async () => {
										console.log('sending');
										try {
											let res = await sendOTP({
												variables: { email: input },
											});
											console.log(res.data.generateOTP);
											res = res.data.generateOTP;
											let title = res.success ? 'Email Sent' : 'Failed to send code';
											toast({
												title: title,
												description: res.message,
												status: res.success ? 'success' : 'error',
												duration: 9000,
												isClosable: true,
											});
											setCodeSent(res.success);
										} catch (e) {
											toast({
												title: 'Error',
												description: "Sorry We've Encountered an Error. Try again later.",
												status: 'error',
												duration: 9000,
												isClosable: true,
											});
										}
									}}>
									Send Verification Code
								</Button>
							</motion.Center>
							{codeSent && (
								<Center w='100%'>
									<Stack width='100%'>
										<Center w='100%'>
											<Heading mt={['25%', '20%', '10%']}>Verification Code</Heading>
										</Center>
										<Center w='100%'>
											<PinInput
												otp
												w='100%'
												value={code}
												borderBottom={'3px solid'}
												onComplete={() => {
													setFullCode(true);
												}}
												onChange={(input) => {
													setCode(input);
													if (input <= 99999) {
														setFullCode(false);
													}
												}}>
												<PinInputField w={pinWidth} h={'50px'} borderBottom={'3px solid'} />
												<PinInputField w={pinWidth} h={'50px'} borderBottom={'3px solid'} />
												<PinInputField w={pinWidth} h={'50px'} borderBottom={'3px solid'} />
												<PinInputField w={pinWidth} h={'50px'} borderBottom={'3px solid'} />
												<PinInputField w={pinWidth} h={'50px'} borderBottom={'3px solid'} />
												<PinInputField w={pinWidth} h={'50px'} borderBottom={'3px solid'} />
											</PinInput>
										</Center>
										<Center w='100%'>
											<Button
												colorScheme={'purple'}
												colorMode='purple'
												variant='outline'
												autoFocus
												borderBottom={'3px solid'}
												w={elementWidth}
												disabled={!fullCode}
												onClick={async () => {
													const res = await verifyCode({
														variables: { code: parseInt(code) },
													});
													console.log(parseInt(code));
													console.log(res.data.verifyCode);
													setValidCode(res.data.verifyCode.success);
													setUserId(res.data.verifyCode.id);
													console.log(res.data.verifyCode.id);
												}}>
												Verify Code
											</Button>
										</Center>
									</Stack>
								</Center>
							)}
							{validCode && (
								<Center width='100%'>
									<Stack width='100%' alignItems={'center'}>
										<Center>
											<Heading mt={['30%', '40%', '20%']}>Reset Password</Heading>
										</Center>
										<Input
											placeholder='New Password'
											p='1%'
											textAlign='center'
											value={pass}
											w={elementWidth}
											onChange={(event) => {
												setPass(event.target.value);
											}}
											type='password'
										/>
										<Input
											placeholder='Verify Password'
											p='1%'
											w={elementWidth}
											textAlign='center'
											value={verifyPass}
											borderBottom={'3px solid'}
											onChange={(event) => {
												setVerifyPass(event.target.value);
											}}
											type='password'
										/>
										<Button
											colorScheme='purple'
											colorMode={'purple'}
											variant='outline'
											w={elementWidth}
											borderBottom={'3px solid'}
											autoFocus
											disabled={verifyPass != pass || pass.length < 8}
											onClick={async () => {
												const res = await resetPass({
													variables: {
														id: userId,
														password: pass,
													},
												});
												setPasswordReset(res.data.resetPassword.success);
												console.log(res.data.resetPassword);
											}}>
											Reset Password
										</Button>
									</Stack>
								</Center>
							)}
							<Center h='20vh'></Center>
						</motion.div>
					</Stack>
				</motion.div>
			)}
			{passwordReset && (
				<Center w='100vw' h='100vh'>
					<Stack>
						<Heading size={'3xl'} textAlign={'center'}>
							Success!
						</Heading>
						<Text>
							Your password has been succesfully reset <br /> You have been logged out of all accounts
						</Text>
					</Stack>
				</Center>
			)}
		</Box>
	);
}
