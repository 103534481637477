import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import AnimatedRoutes from './Routes/AnimatedRoutes';
import '@fontsource/inter';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import ReactGA from 'react-ga4';
import { UserProvider } from './UserContext';
import { SettingsProvider } from './SettingsConfig';
import { setContext } from '@apollo/client/link/context';
import config from './environment';
const relayTheme = extendTheme({
	semanticTokens: {
		colors: {
			error: 'red.500',
			text: {
				default: 'white.100',
				_dark: 'gray.50',
			},
			heading: {
				default: 'white',
			},
		},
	},
	fonts: {
		// heading: `'Inter/700', sans-serif`,
		// body: `'Inter/500', sans-serif`,
	},
	colors: {
		relayPurple: {
			full: 'rgba(94, 45, 235, 1)',
			100: 'rgba(94, 45, 235, .1)',
			500: 'rgba(94, 45, 235, .5)',
		},
	},
	components: {
		Heading: {
			color: 'white',
		},
		Button: {
			variants: {
				outline: (props) => ({
					borderColor: props.colorMode == 'purple' ? 'rgba(94, 45, 235, 1)' : 'rgba(255, 255, 255, 1)',
					borderWidth: 2,
					bg: props.colorMode == 'purple' ? 'rgba(94, 45, 235, 0.1)' : 'rgba(255, 255, 255, 1)',
					_hover: {
						bg: props.colorMode == 'purple' ? 'rgba(94, 45, 235, 0.5)' : 'rgba(255, 255, 255, 0.5)',
					},
					borderRadius: 8,
				}),
				ghost: (props) => ({
					_hover: {
						transform: 'scale(1.1)',
						bg: 'rgb(0,0,0,0)',
					},
				}),
			},
		},
	},
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		console.log('graphQLErrors', graphQLErrors);
	}
	if (networkError) {
		console.log('networkError', networkError);
	}
});
const link = new HttpLink({ uri: `${config.server}:${config.port}/graphql` });

// const link = new HttpLink({ uri: `${'http://18.189.194.54'}:3000/graphql` });
const headerLink = setContext(async (request, previousContext) => {
	const authorization = localStorage.getItem('accessToken') || '';
	const refresh = localStorage.getItem('refreshToken') || '';
	return {
		headers: {
			refresh: refresh,
			authorization: authorization,
		},
	};
});

const client = new ApolloClient({
	// uri: `${'http://172.16.224.44'}:3000/graphql`,
	uri: `${config.server}:3000/graphql`,
	cache: new InMemoryCache(),
	link: headerLink.concat(ApolloLink.from([link, errorLink])),
	fetchOptions: {
		mode: 'no-cors',
	},
});
const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize('G-PC8W5WJSQJ');

root.render(
	<BrowserRouter>
		<ChakraProvider theme={relayTheme}>
			<ApolloProvider client={client}>
				<UserProvider>
					<SettingsProvider>
						<React.StrictMode>
							<AnimatedRoutes />
							<Footer />
						</React.StrictMode>
					</SettingsProvider>
				</UserProvider>
			</ApolloProvider>
		</ChakraProvider>
	</BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
