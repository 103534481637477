import React, { useEffect, useContext, useState, useRef, componentDidCatch } from 'react';
import {
	ChakraProvider,
	Grid,
	GridItem,
	Text,
	Image,
	Wrap,
	WrapItem,
	Center,
	Stack,
	Skeleton,
	SkeletonText,
	Alert,
	AlertIcon,
	Progress,
	Button,
	HStack,
	Heading,
	useToast,
	Kbd,
} from '@chakra-ui/react';
import TextPost from './TextPost';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import URLPost from './URLPost';
import ImagePost from './ImagePost';
import { useQuery, gql } from '@apollo/client';
import DropUpload from '../DropUpload';
import { UserContext } from '../../UserContext';
import { SettingsContext } from '../../SettingsConfig';
import Announcement from './Announcement';
import socketIOClient, { io } from 'socket.io-client';
import FilePost from './FilePost';
import AudioPost from './AudioPost';
import VideoPost from './VideoPost';
import { redirect } from 'react-router-dom';
import config from '../../environment';
import dumpy from '../../Images/dumpus_cropped_1.webp';
import { colors } from '../../Colors';
import RelayLogo from '../../Images/LOGO_TEMP.webp';
// const fs = require('fs');
// const Store = require('electron-store');
// const store = new Store();
const ENDPOINT = `${config.server}:4000`;

let canRecieveNotif = true;

export default function Body() {
	const toast = useToast();
	const [errored, setErrored] = useState(false);
	const [isOpenDownload, setIsOpenDownload] = useState(false);
	const [seenAnnouncements, setSeenAnnouncements] = useState(true);
	const onClose = () => setIsOpenDownload(false);
	const cancelRef = useRef();
	const { user, refresh, setRefresh, saved, sent } = useContext(UserContext);
	const { fileSending, setFileSending, showQuickDrop } = useContext(SettingsContext);
	const GET_POSTS = gql`
		query ($userId: String!) {
			postsByUserID(userId: $userId) {
				content
				type
				sender
				_id
				name
				extension
			}
		}
	`;
	const GET_ANNOUNCEMENTS = gql`
		query {
			getHighlightedAnnouncement {
				_id
				type
				content
				thumbnail
				link
				title
			}
		}
	`;
	useEffect(() => {
		console.log('user');
		console.log(user);
		if (user.username == 'NOT LOADED') {
			console.log('redirecting back to login');
			return (
				<motion.div
					animate={{ opacity: 1 }}
					initial={{ opacity: 0 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.5, ease: 'easeIn' }}>
					Logging you into Relay
				</motion.div>
			);
			// window.open('http://localhost:3000/login', '_self');
		}
	}, []);

	const download = (url, path, callback) => {
		var link = document.createElement('a');
		link.setAttribute('download', path);
		link.href = url;
		document.body.appendChild(link);
		link.click();
		link.remove();
		callback();
	};
	const { loading, error, data, refetch } = useQuery(GET_POSTS, {
		variables: { userId: user._id },
		pollInterval: 1000 * 600, // 10 minutes
	});
	const announcementQuery = useQuery(GET_ANNOUNCEMENTS);
	let announcements = announcementQuery.data;
	// const { ipcRenderer } = require('electron');
	// ipcRenderer.send('logout', true);
	useEffect(() => {
		const socket = socketIOClient(ENDPOINT);
		socket.on('test', (data) => {
			console.log('socket moment');
			console.log(data);
			socket.emit('join-room', user._id);
		});
		socket.on('refresh', (data) => {
			console.log('sending a refresh!');
			console.log('data:');
			console.log(data);
			// const Store = require('electron-store');
			// const store = new Store();
			// if (canRecieveNotif && store.enableNotifications) {
			// 	const NOTIFICATION_TITLE = 'Relay Drop';
			// 	const NOTIFICATION_BODY =
			// 		'Looks like you recieved a drop! The post has been automatically copied to clipboard';
			// 	const CLICK_MESSAGE = 'Notification clicked';

			// 	new Notification(NOTIFICATION_TITLE, {
			// 		body: NOTIFICATION_BODY,
			// 	}).onclick = () => console.log(CLICK_MESSAGE);
			// 	canRecieveNotif = false;
			// 	setTimeout(() => {
			// 		canRecieveNotif = true;
			// 	}, 1000);
			// }
			console.log('refetching');
			console.log(user._id);
			refetch({
				variables: { userId: user._id },
			});
		});
		console.log('from effect');

		socket.on('disconnect', () => {
			let date = new Date();
			console.log('we just diconnected lolw');
			console.log(date.getHours() + ':' + date.getMinutes());
		});
		return () => socket.disconnect();
	}, []);

	useEffect(() => {
		console.log(data);
		console.log('theres new data');
		if (typeof data == 'undefined') return;
		const { postsByUserID } = data;
		if (typeof postsByUserID == 'undefined') return;
		if (postsByUserID.length > 0) {
			console.log('the data has been lowkey updated');
			// Auto Copy
			// if (postsByUserID[postsByUserID.length - 1].sender != require('os').hostname()) {
			// 	const Store = require('electron-store');
			// 	const store = new Store();
			// 	if (store.get('autoCopy')) {
			// 		require('electron').clipboard.writeText(postsByUserID[postsByUserID.length - 1].content);
			// 	}
			// }
			console.log(postsByUserID[postsByUserID.length - 1].content);
		}
	}, [data]);
	useEffect(() => {
		console.log('told to refresh');
		if (refresh) {
			console.log('refreshing:');
			console.log(user._id);
			refetch({ variables: { userId: user._id } });
			setRefresh(false);
		}
	}, [refresh]);
	useEffect(() => {
		if (announcements == null) return;
		if (announcements.getHighlightedAnnouncement.length > 0) {
			if (localStorage.getItem(announcements.getHighlightedAnnouncement._id)) {
				setSeenAnnouncements(true);
				return;
			}
			setSeenAnnouncements(false);
			console.log('announcements found');
			console.log(announcements.getHighlightedAnnouncement);
		} else {
			setSeenAnnouncements(true);
		}
	}, [announcements]);
	if (loading) {
		console.log(loading);
		return (
			<Center style={{ width: '100%' }}>
				<Stack style={{ transform: 'translate(-0%,0%)' }}>
					<motion.div
						animate={{ opacity: 1 }}
						initial={{ opacity: 0 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 1, ease: 'easeIn' }}>
						<Image
							width='25vw'
							height='25vw'
							style={{ marginTop: '75%', margin: 'auto' }}
							src={dumpy}
							fallback={'Loading...'}
						/>
						Loading your posts...
					</motion.div>
					{/* 
          <SkeletonText mt="4" noOfLines={8} spacing="5" width="80vw" /> */}
				</Stack>
			</Center>
		);
	}
	if (error) {
		console.log(error);
	}

	const showPosts = () => {
		if (data.postsByUserID.length > 0) {
			// user posts are found
			return data.postsByUserID
				.slice(0)
				.reverse()
				.map(({ content, type, sender, _id, name, extension }) => {
					let rand = Math.random();
					switch (type) {
						case 'text':
							console.log(_id);
							return (
								<AnimatePresence>
									<motion.div
										style={{ width: '100%' }}
										whileHover={{ scale: 1.05 }}
										animate={{ x: 0 }}
										initial={{ x: 100 * (rand > 0.5 ? 1 : -1) }}
										exit={{ opacity: 0 }}
										transition={{ duration: 1, ease: 'circOut' }}>
										<TextPost content={content} sender={sender} id={_id} />
									</motion.div>
								</AnimatePresence>
							);
						case 'image':
							return (
								<AnimatePresence>
									<Center>
										<ImagePost content={content} sender={sender} id={_id} name={name} />
									</Center>
								</AnimatePresence>
							);
						case 'url':
							return (
								<AnimatePresence>
									<motion.div
										style={{ width: '100%' }}
										whileHover={{ scale: 1.05 }}
										animate={{ x: 0 }}
										initial={{ x: 100 * (rand > 0.5 ? 1 : -1) }}
										exit={{ opacity: 0 }}
										transition={{ duration: 1, ease: 'circOut' }}>
										<URLPost content={content} sender={sender} id={_id} />
									</motion.div>
								</AnimatePresence>
							);

						case 'audio':
							return (
								<AnimatePresence>
									<Center>
										<AudioPost
											content={content}
											sender={sender}
											id={_id}
											type={type}
											name={name}
											extension={extension}
										/>
									</Center>
								</AnimatePresence>
							);
						case 'video':
							return (
								<AnimatePresence>
									<Center>
										<VideoPost
											content={content}
											sender={sender}
											id={_id}
											type={type}
											name={name}
											extension={extension}
										/>
									</Center>
								</AnimatePresence>
							);
						default:
							console.log('FILE POST');
							return (
								<AnimatePresence>
									<Center>
										<FilePost
											content={content}
											sender={sender}
											id={_id}
											type={type}
											name={name}
											extension={extension}
										/>
									</Center>
								</AnimatePresence>
							);
					}
				});
		} else {
			const noPostsMessage = [
				'Looks a Little Empty Here',
				'No New Posts',
				//  'Were you expecting something here?',
				'Nothing new to show',
				'0 posts here, Lets change that!',
				'Looks like theres no new posts',
				// 'Is it time to get a post?',
				'Its Empty!',
			];
			const getRandomMessage = () => {
				var message = noPostsMessage[Math.floor(Math.random() * noPostsMessage.length)];
				return message;
			};
			return (
				// no user posts were made yet
				<Center style={{ width: '100%' }}>
					{/* <Heading color="white">Relay</Heading> */}
					<Stack
						style={{
							// transform: 'translate(-0%,-0%)',
							width: '75vw',
							marginTop: '2vh',
							textAlign: 'center',
							alignSelf: 'center',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<Image width='20vw' height='20vw' style={{}} src={dumpy}></Image>
						<Center w='100%' h='100%'>
							<Text
								style={{
									height: '100vh',
									textAlign: 'center',
									width: '100%',
									fontFamily: 'Helvetica',
									fontWeight: '700',
									color: 'rgba(255,255,255,0.5)',
								}}
								fontSize='2xl'>
								{getRandomMessage()}
								<Text
									fontSize='md'
									style={{
										textAlign: 'center',
										width: '100%',
										fontFamily: 'Helvetica',
										fontWeight: '300',
										color: 'rgba(255,255,255,0.5)',
									}}>
									Did you know you can use <Kbd color='black'>Ctrl</Kbd> + <Kbd color='black'>V</Kbd>{' '}
									to paste in Relay?
								</Text>
							</Text>
						</Center>
					</Stack>
				</Center>
			);
		}
	};
	if (errored) {
		return <div>Errored</div>;
	}
	return (
		<AnimatePresence>
			<motion.div
				animate={{ opacity: 1 }}
				initial={{ opacity: 0 }}
				transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}>
				{!seenAnnouncements && <Announcement announcements={announcements.getHighlightedAnnouncement} />}

				<Wrap
					spacing='20px'
					align='center'
					paddingX='2%'
					width='90vw'
					mt='11vh'
					bg={colors.background}
					color='#fff'
					height='100vh'>
					<Center>
						<WrapItem w='100%'>{showQuickDrop && <DropUpload width='80' left='19' button />}</WrapItem>
					</Center>
					<WrapItem w='100%'>
						<Stack ml='10%' mt={showQuickDrop ? '2vh' : '5vh'} width='100%'>
							{/* <Heading
								fontFamily='Helvetica'
								color='white'
								opacity='1'
								size='md'
								flexDirection={'row'}
								textAlign={'left'}
								flex='1'>
								<Image src={RelayLogo} h='8vh' ml='-1%' display='inline-block' />
							</Heading> */}
							<motion.div
								transition={{
									delay: 0.5,
									duration: 0.4,
									type: 'spring',
								}}
								duration={0.4}
								animate={{ x: 0, opacity: 1 }}
								initial={{ x: -500, opacity: 0 }}
								exit={{ x: -300, opacity: 0 }}>
								<Heading
									fontSize='3rem'
									fontWeight='700'
									textAlign={'left'}
									fontFamily={'Helvetica'}
									lineHeight={'1'}
									mb='0px'>
									Your <div style={{ color: '#8EB5F4', display: 'inline' }}>Drops</div>
									<Button
										variant='ghost'
										mt='0%'
										ml='2%'
										color={colors.secondary}
										w='10%'
										onClick={() => {
											setIsOpenDownload(true);
										}}>
										Download All
									</Button>
								</Heading>

								<Text
									// fontFamily={'Helvetica'}
									fontWeight='light'
									fontSize='1rem'
									mt='0px'
									color={colors.offwhite}>
									Showing {data.postsByUserID.length || 0} drops
								</Text>
							</motion.div>
						</Stack>
					</WrapItem>
					{fileSending.length > 0 && (
						<Stack w='100%' pl='10%'>
							{fileSending.map((file) => {
								return (
									<Center w='90%'>
										<Text fontFamily='Inter' pr='5%'>
											{file.name} ({file.size} MB)
										</Text>
										<Progress hasStripe isIndeterminate w='60%' />
									</Center>
								);
							})}
						</Stack>
					)}
					<AlertDialog isOpen={isOpenDownload} leastDestructiveRef={cancelRef} onClose={onClose}>
						<AlertDialogOverlay>
							<AlertDialogContent>
								<AlertDialogHeader fontSize='lg' fontWeight='bold'>
									Download All Files
								</AlertDialogHeader>

								<AlertDialogBody>
									This will download every new imported file. However, Relay will not download files
									which have already been downloaded. Do you wish to continue?
								</AlertDialogBody>

								<AlertDialogFooter>
									<Button ref={cancelRef} onClick={onClose}>
										Cancel
									</Button>
									<Button
										colorScheme='purple'
										onClick={async () => {
											onClose();
											console.log(data);
											toast({
												title: 'Beginning Download...',
												description:
													'Hold on as we begin your download. Your download can be found in your downloads folder',
												status: 'info',
												duration: 10000,
												isClosable: true,
												position: 'top',
											});
											data.postsByUserID.forEach(async ({ content, _id, type, extension }) => {
												console.log(_id);
												// const Store = require('electron-store');
												// const store = new Store();
												//  && !store.has(_id)
												if (type != 'text' && type != 'url') {
													console.log('saving!!');
													let ext = type == 'image' ? 'png' : extension;
													// let path = await ipcRenderer.invoke('getDownloadPath');
													let path = '';
													download(content, `${path}RelayUpload${_id}.${ext}`, () => {
														localStorage.setItem(`${_id}`, true);
														console.log('✅ Done!');
													});
												}
											});
											toast({
												title: 'Finished Download!',
												description: 'Your download can be found in your downloads folder',
												status: 'success',
												duration: 10000,
												isClosable: true,
												position: 'top',
											});
										}}
										ml={3}>
										Download All
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>
					<AnimatePresence>
						{saved && (
							<WrapItem w='90vw' padding='0px' h='10vh'>
								<motion.div
									animate={{ y: 0, opacity: 1 }}
									initial={{ y: -100, opacity: 0 }}
									exit={{ y: -100, opacity: 0 }}>
									<Alert
										style={{ color: 'black' }}
										borderRadius='lg'
										status='success'
										variant='subtle'
										flexDirection='column'
										alignItems='center'
										justifyContent='center'
										textAlign='center'
										height='15vh'
										width='75vw'
										ml='8vw'
										backgroundColor='rgba(50,255,50,0.2)'
										borderColor='rgba(50,255,50,0.4)'
										borderWidth={3}
										color='white'
										padding='2%'>
										<Heading color='green.500' size='lg' padding='0%'>
											Image Saved!
										</Heading>
										<Text color='white'>
											Your image has been saved! It should be within your downloads folder.
										</Text>
									</Alert>
								</motion.div>
							</WrapItem>
						)}
						{sent && (
							<WrapItem w='90vw' padding='0px' h='9vh'>
								<motion.div
									animate={{ y: 0, opacity: 1 }}
									initial={{ y: -100, opacity: 0 }}
									exit={{ y: -100, opacity: 0 }}>
									<Alert
										style={{ color: 'black' }}
										borderRadius='lg'
										status='success'
										variant='subtle'
										flexDirection='column'
										alignItems='center'
										justifyContent='center'
										textAlign='center'
										height='15vh'
										width='75vw'
										ml='8vw'
										backgroundColor='rgba(50,255,50,0.2)'
										borderColor='rgba(50,255,50,0.4)'
										borderWidth={3}
										color='white'
										padding='2%'>
										<Heading color='green.500' size='lg' padding='0%'>
											Delivered!
										</Heading>
										<Text color='white'>
											Your drop has been sent! Check it out on your other devices.
										</Text>
									</Alert>
								</motion.div>
							</WrapItem>
						)}
					</AnimatePresence>
					<motion.div
						transition={{
							delay: 1,
							duration: 0.5,
							timingFunction: 'cubic-bezier(0.17, 0.67, 0.83, 0.67)',
						}}
						animate={{ opacity: 1 }}
						initial={{ opacity: 0 }}
						exit={{ opacity: 0 }}>
						<WrapItem bg={colors.background} w='100%'>
							<Wrap mt='3%' ml='10%' w='90%' pb='10vh' bg={colors.background} spacing='12px'>
								{showPosts()}
							</Wrap>
						</WrapItem>
					</motion.div>
				</Wrap>
			</motion.div>
		</AnimatePresence>
	);
}
