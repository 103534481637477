import './Home.css';
import { Heading, Stack, Text, Button, Image, HStack } from '@chakra-ui/react';
import Navbar from '../Components/Navbar';
import SplashPage from '../Components/SplashPage';
import SplashNew from '../Components/SplashNew';
import Content from '../Components/Content';
import Footer from '../Components/Footer';
function Home() {
	let width = window.innerWidth;
	let height = window.innerHeight;
	return (
		<div style={{ backgroundColor: '#000019' }}>
			<Navbar />
			<SplashNew />
			<Content />
		</div>
	);
}

export default Home;
