import React from 'react';

import { motion } from 'framer-motion';
import { Heading, Text, Image, Stack } from '@chakra-ui/react';

export default function ContentBar({ picture, name, text, color, right }) {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
			transition={{ duration: 1, delay: 0.5 }}
			viewport={{ once: true }}
			style={{
				width: '80%',
				flex: 1,
				display: 'flex',
				padding: '7rem 2rem',
				// marginLeft: right ? '10%' : '-10%',
				alignSelf: 'center',
				borderRadius: '30px',
				backgroundColor: `rgba(${color}, 0.05)`,
				background: `linear-gradient(to ${right ? 'right' : 'left'}, rgba(0,0,19,1), rgba(${color},0.05))`,
				// backgroundGradient: `linear-gradient(to right, rgba(${color},0.1), rgba(${color},0.2))`,
				backgroundBlendMode: 'overlay',
				// boxShadow: `0px 0px 5px 2px rgba(${color},0.1)`,
			}}>
			<Stack direction={['column', 'column', right ? 'row' : 'row-reverse']}>
				<Image
					src={picture}
					width={['50%', '50%', '35%', '30%']}
					height={['50%', '50%', '80%', '90%']}
					alignSelf={['center', right ? 'flex-end' : 'flex-start']}
					borderRadius={10}
					dropShadow={10}></Image>
				<div
					style={{
						marginLeft: '1rem',
						verticalAlign: 'middle',
						height: '100%',
						textAlign: right ? 'right' : 'left',
					}}>
					<Heading
						color='white'
						size='xl'
						marginRight={right ? '10%' : '0%'}
						marginLeft={right ? '0%' : '10%'}
						marginTop='2%'>
						{/* all words in name except the last one */}
						{name
							.split(' ')
							.splice(0, name.split(' ').length - 1)
							.join(' ')}{' '}
						<span style={{ color: `rgb(${color})` }}>{name.split(' ')[name.split(' ').length - 1]}</span>
					</Heading>
					<Text
						marginRight={right ? '20%' : '0%'}
						marginLeft={right ? '0%' : '10%'}
						color='white'
						fontSize={['md', 'lg']}
						w='100%'
						paddingLeft={right ? '20%' : '0%'}
						paddingRight={right ? '10%' : '20%'}
						mt='0.5rem'>
						{text}
					</Text>
				</div>
			</Stack>
		</motion.div>
	);
}
