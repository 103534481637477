import React, { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	Heading,
	Image,
	Text,
	Center,
} from '@chakra-ui/react';

export default function Announcement({ announcements }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		onOpen();
	}, []);
	return (
		<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
			<ModalOverlay />
			<ModalOverlay />
			<ModalContent w='50%' bg='#09002F' minWidth='50vw'>
				<ModalHeader textAlign='center' color='white'>
					<Heading style={{ fontFamily: 'Inter' }}>{announcements[0].type}</Heading>
				</ModalHeader>
				<ModalCloseButton color='white' />
				<ModalBody color='white'>
					<Center>
						<Image
							src={announcements[0].thumbnail}
							h='30vh'
							alignSelf='center'
							justifyContent='center'
							justifySelf='center'
							//   mt="10%"
						/>
					</Center>
					<Center>
						<Heading mt='5%' style={{ fontFamily: 'Inter' }}>
							{announcements[0].title}
						</Heading>
					</Center>
					<Center w='100%'>
						<Text mt='5%' fontSize='md' w='80%' style={{ fontWeight: 300, fontFamily: 'Inter' }}>
							{announcements[0].content}
						</Text>
					</Center>
					<Center w='100%'>
						<Text mt='5%' fontSize='md' w='80%' textAlign='right' style={{ fontFamily: 'Inter' }}>
							Thanks,
							<br />
							Abhik
						</Text>
					</Center>
					<Center>
						<Button
							variant='link'
							colorScheme='purple'
							mt='2%'
							padding='1%'
							onClick={() => {
								window.open(announcements[0].link, '_blank').focus();
								// require('electron').shell.openExternal(announcements[0].link);
							}}>
							Learn More
						</Button>
					</Center>
					<Center>
						<Button
							variant='link'
							colorScheme='purple'
							textAlign='left'
							padding='1%'
							onClick={() => {
								// require('electron').shell.openExternal('https://www.instagram.com/relayapp.gg/?hl=en');
							}}>
							Follow Relay on Instagram!
						</Button>
					</Center>
				</ModalBody>

				<ModalFooter>
					<Button
						colorScheme='red'
						mr={3}
						onClick={() => {
							// const Store = require('electron-store');
							// const store = new Store();
							localStorage.setItem(announcements[0]._id, true);
							onClose();
						}}>
						Dont Show Again
					</Button>
					<Button colorScheme='purple' onClick={onClose}>
						Dismiss
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
