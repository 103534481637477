import { Center, HStack, Menu, MenuButton } from '@chakra-ui/react';
import React from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { a, Nava } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IconButton, MenuList, MenuItem } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
export default function Navbar() {
	return (
		<Center position='sticky' top='0px' zIndex={99} backdropBlur={'lg'}>
			<motion.div
				layout
				transition={{ delay: 1 }}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				// style={{ justifyContent: 'right' }}
			>
				{window.innerWidth > 600 && (
					<Box
						bg='rgba(17,28,96,0.1)'
						borderRadius={20}
						borderWidth={2}
						borderColor='#111c60'
						borderStyle={'solid'}
						padding='1%'
						fontWeight='normal'
						position={'absolute'}
						top={5}
						right='5vw'
						backdropFilter='auto'
						backdropBlur='6px'>
						<HStack color='white' backdropBlur={'lg'}>
							<Button
								variant={'ghost'}
								backdropBlur={'lg'}
								style={{ color: 'white' }}
								onClick={() => {
									let downloadText = document.getElementById('download');
									downloadText.scrollIntoView({
										behavior: 'smooth',
										block: 'center',
										inline: 'nearest',
									});
								}}>
								Download
							</Button>
							<Button variant={'ghost'} style={{ color: 'white' }}>
								<a
									href='https://beakplusemi.notion.site/About-3871d9c0793b4f6aac22d6d346129927'
									target='_blank'>
									About
								</a>
							</Button>
							<Button variant={'ghost'} style={{ color: 'white' }}>
								<a
									href='https://beakplusemi.notion.site/Help-e71c1a82560f40beabc5d80bec6ddc9a'
									target='_blank'>
									Help
								</a>
							</Button>
							<Button
								variant='ghost'
								style={{ color: 'white' }}
								onClick={() => {
									console.log('rerms');
								}}>
								<a
									href='https://beakplusemi.notion.site/Terms-Service-bdbb2e6f0ae54b49acbd840c7eb91ff4'
									target='_blank'>
									Terms
								</a>
							</Button>
						</HStack>
					</Box>
				)}
				{window.innerWidth < 600 && (
					<Menu mt='10%'>
						<MenuButton
							as={IconButton}
							aria-label='Options'
							icon={<HamburgerIcon />}
							variant='outline'
							bg='rgba(255,255,255,0.3)'
							color='white'
							padding='2%'
							fontWeight='normal'
							position={'absolute'}
							backdropFilter='auto'
							backdropBlur='6px'
							right='5vw'
							mt='5%'></MenuButton>
						<MenuList
							textAlign={'center'}
							// bg='rgba(0,25,100,0.2)'
							// color='white'
							fontWeight={600}>
							<MenuItem
								onClick={() => {
									let downloadText = document.getElementById('download');
									downloadText.scrollIntoView({
										behavior: 'smooth',
										block: 'center',
										inline: 'nearest',
									});
								}}>
								<Heading size='sm'>Download</Heading>
							</MenuItem>
							<MenuItem>
								<Heading size='sm'>
									{' '}
									<a
										href='https://beakplusemi.notion.site/About-3871d9c0793b4f6aac22d6d346129927'
										target='_blank'>
										About
									</a>
								</Heading>
							</MenuItem>
							<MenuItem>
								<Heading size='sm'>Support</Heading>
							</MenuItem>
							<MenuItem>
								<Heading size='sm'>
									{' '}
									<a
										href='https://beakplusemi.notion.site/Help-e71c1a82560f40beabc5d80bec6ddc9a'
										target='_blank'>
										Help
									</a>
								</Heading>
							</MenuItem>
							<MenuItem>
								<Heading size='sm'>
									{' '}
									<a
										href='https://beakplusemi.notion.site/Terms-Service-bdbb2e6f0ae54b49acbd840c7eb91ff4'
										target='_blank'>
										Terms
									</a>
								</Heading>
							</MenuItem>
						</MenuList>
					</Menu>
				)}
			</motion.div>
		</Center>
	);
}
