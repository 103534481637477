import React, { useContext, useState, useEffect, useCallback } from 'react';
import S3 from 'react-aws-s3';
import { Image, Center, toast, useToast } from '@chakra-ui/react';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import { UserContext } from '../../UserContext';
import { SettingsContext } from '../../SettingsConfig';
import { Tooltip } from '@chakra-ui/react';
import clipIcon from '../../Images/clip.png';
let validUrl = require('valid-url');
export default function ClipboardIcon() {
	const toast = useToast();
	const { user, setSent } = useContext(UserContext);
	const { spamVal, setSpamVal } = useContext(SettingsContext);
	const [canSendShortcut, setShortcut] = useState(true);
	const [disabled, setDisabled] = useState(false);
	// handle what happens on key press
	const handleKeyPress = useCallback(async (event) => {
		if ((event.key === 'v' && event.ctrlKey) || (event.key === 'v' && event.metaKey)) {
			console.log(`Key pressed: ${event.key}`);
			await sendFromClip();
		}
	}, []);

	useEffect(() => {
		// attach the event listener
		document.addEventListener('keydown', handleKeyPress);

		// remove the event listener
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);
	console.log(spamVal);
	const updateSpamVal = () => {
		console.log(spamVal);
		setSpamVal(spamVal + 1);
		setTimeout(() => {
			setSpamVal(spamVal - 1);
		}, 3500);
	};
	const CREATE_POST = gql`
		mutation ($type: String!, $content: String!, $sender: String!, $userId: String!) {
			createPost(type: $type, content: $content, sender: $sender, userId: $userId) {
				success
				message
			}
		}
	`;
	const IS_DYNO = gql`
		query {
			isDyno(dyno: boolean)
		}
	`;
	const [createPost] = useMutation(CREATE_POST);
	const [fetchDyno, { loading, error, data }] = useLazyQuery(IS_DYNO);
	const sendFromClip = async () => {
		if (disabled) return;
		else {
			setDisabled(true);
			setTimeout(() => {
				setDisabled(false);
			}, 1000);
			let clip = navigator.clipboard;
			const clipboardContents = await clip.read();
			updateSpamVal();
			for (const item of clipboardContents) {
				console.log('Sending one item');
				if (item.types.includes('image/png') || item.types.includes('image/jpg')) {
					let name =
						Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
					let img = clip.readImage();
					console.log(img.toDataURL());
					const base64Response = await fetch(img.toDataURL());
					const blob = await base64Response.blob();
					console.log(blob);
					let imgFile = new File([blob], name, { type: 'image/png' });
					console.log(imgFile);
					const config = {
						bucketName: 'relay-file-upload',
						region: 'us-east-2',
						accessKeyId: 'AKIAZ7OW6KENMSPV5FRF',
						secretAccessKey: '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',
					};
					const ReactS3Client = new S3(config);

					console.log(name);
					ReactS3Client.uploadFile(imgFile, imgFile.name + '.png').then((data) => {
						console.log(data);
						if (spamVal > 2) {
							console.log('spam warning');
						} else {
							updateSpamVal();
							createPost({
								variables: {
									type: 'image',
									content: data.location,
									sender: 'Browser',
									userId: user._id,
								},
							}).then((res) => {
								setSent(true);
								setTimeout(() => setSent(false), 2000);
							});
						}
					});
				} else {
					let text = await clip.readText();
					let type = validUrl.isUri(text) ? 'url' : 'text';
					console.log('Clipboard Text: ');
					console.log(text);
					if (spamVal > 2) {
						console.log('spam warning');
					} else {
						updateSpamVal();
						createPost({
							variables: {
								type: type,
								content: text,
								sender: 'Browser',
								userId: user._id,
							},
						})
							.then(({ data }) => {
								console.log(data);
								const result = data.createPost;
								if (result.success) {
									setSent(true);
									setTimeout(() => setSent(false), 2000);
								} else {
									toast({
										position: 'bottom',
										title: 'Could Not Send Post',
										description: result.message,
										status: 'error',
										duration: 5000,
										isClosable: true,
									});
								}
							})
							.catch((err) => {
								console.log(err);
								toast({
									position: 'bottom',
									title: 'Oops! We have encountered an error',
									description: err,
									status: 'error',
									duration: 5000,
									isClosable: true,
								});
							});
					}
				}
			}
		}
	};

	useEffect(() => {
		// Shortcut
		// ipcRenderer.on('sendClipboard', (event, data) => {
		// 	console.log('sending clipboard in COMPONENT!');
		// 	if (canSendShortcut) {
		// 		console.log('sending!');
		// 		setShortcut(false);
		// 		sendFromClip();
		// 		setTimeout(() => {
		// 			setShortcut(true);
		// 		}, 1000);
		// 		// ipcRenderer.removeAllListeners('sendClipboard');
		// 	} else {
		// 		setShortcut(false);
		// 		console.log('BLOCKED');
		// 	}
		// });
	}, []);

	return (
		<div>
			<Tooltip
				label='Send a drop from your clipboard (Alt+Shift+V)'
				placement='right'
				fontSize='sm'
				openDelay={500}
				size='md'>
				<Center>
					<Image
						borderRadius='full'
						boxSize='70%'
						transition={'opacity 0.5s ease-in-out'}
						src={clipIcon}
						opacity={disabled ? 0.5 : 1}
						alt='Segun Adebayo'
						onClick={sendFromClip}
					/>
				</Center>
			</Tooltip>
		</div>
	);
}
// ipcRenderer.on('sendClipboard', (event, data) => {
//   console.log('sending clipboard!');
//   // if (canSendShortcut) {
//   //   sendFromClip();
//   //   setShortcut(false);
//   //   setTimeout(setShortcut(true), 1000);
//   //   ipcRenderer.removeAllListeners('sendClipboard');
//   // } else {
//   //   console.log('BLOCKED');
//   // }
// });
