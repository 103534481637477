import { Center, Divider, Heading, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export default function Footer() {
	return (
		<Center bg='rgb(0,0,35)' h={['20vh', '5vh']} p='2%'>
			<Stack direction='row'>
				<Heading size='md' color='rgba(200,200,255,1)' float='left'>
					Relay
				</Heading>
				{window.innerWidth > 600 && (
					<Text color='rgba(200,200,255,1)' fontSize='md' fontWeight={'200'} w='50%'>
						Anything Anywhere
					</Text>
				)}
				<Divider
					orientation='vertical'
					borderColor='white'
					color='white'
					bgColor='rgba(200,200,255,1)'
					borderWidth='1px'
					h='25px'
				/>
				<Text color='rgba(200,200,255,1)'>relay@relayapp.gg</Text>
			</Stack>
		</Center>
	);
}
