import React, { useState, useContext } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
	Image,
	HStack,
	WrapItem,
	useDisclosure,
	Text,
	Button,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { SettingsContext } from '../../SettingsConfig';
import { UserContext } from '../../UserContext';
import { motion } from 'framer-motion';
import fallback from '../../Images/fb.png';
// import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
// const fs = require('electron').remote.require('fs');
export default function ImagePost({ content, sender, id, name }) {
	const { refresh, setRefresh, setSaved } = useContext(UserContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const CLEAR_POST = gql`
		mutation ($postId: String!) {
			clearPost(postId: $postId)
		}
	`;

	const download = (url, path, callback) => {
		var link = document.createElement('a');
		link.setAttribute('download', path);
		link.href = url;
		document.body.appendChild(link);
		link.click();
		link.remove();
		callback();
	};

	const [clearPost, { loading, error, data }] = useMutation(CLEAR_POST);
	// const popOver
	let rand = Math.random();
	return (
		<WrapItem>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				size='full'
				style={{ borderRadius: '10px', height: '100%' }}>
				<ModalOverlay style={{ backgroundColor: 'rgba(0,0,0,0.8)' }} />
				<ModalCloseButton></ModalCloseButton>
				<ModalContent
					style={{
						width: '40%',
						marginTop: '40vh',
						backgroundColor: 'rgba(0,0,0,0)',
					}}
					onClick={() => {
						onClose();
					}}>
					<h2 style={{ color: 'white', fontSize: '1.5em', textAlign: 'center', fontWeight: 800 }}>{name}</h2>
					<Image
						src={content}
						fit='contain'
						height='100%'
						width='100%'
						mb='0%'
						style={{ marginBottom: '0%' }}
						onClick={() => {
							window.open(content, '_blank').focus();
						}}
						fallbackSrc={fallback}></Image>

					<HStack width='100%' mb='2%'>
						<Text
							style={{ color: 'white' }}
							onClick={() => {
								window.open(content, '_blank').focus();
							}}
							width='50%'>
							Click to Open in Browser
						</Text>
						<Text
							width='50%'
							style={{
								color: 'white',
							}}
							textAlign='right'
							onClick={() => {
								window.open(content, '_blank').focus();
							}}>
							Sent By: {sender}
						</Text>
					</HStack>
					<a href={content} download target='_blank' rel='noreferrer' style={{ width: '100%' }}>
						<Button
							colorScheme='green'
							width={'100%'}
							onClick={async () => {
								let path = '';
								let foundGoodPath = false;
								let retries = 0;
								let nameNoExtension = name.substring(0, name.lastIndexOf('.'));
								let fileLocation = `${path}/${nameNoExtension}.png`;
								// download(content, fileLocation, () => {
								localStorage.setItem(`${id}`, true);
								console.log('✅ Done!');
								// });
								setSaved(true);
								setTimeout(() => {
									setSaved(false);
								}, 5000);
							}}>
							Save
						</Button>
					</a>
					<Button
						colorScheme='green'
						onClick={async () => {
							let clip = navigator.clipboard;
							clip.writeText(content);
						}}>
						Copy to Clipboard
					</Button>
					<Button
						mt='1%'
						colorScheme='red'
						onClick={() => {
							clearPost({ variables: { postId: id } });
							setRefresh(true);
						}}>
						Delete
					</Button>
				</ModalContent>
			</Modal>
			<motion.div
				whileHover={{ scale: 1.1 }}
				animate={{ x: 0 }}
				initial={{ x: 100 * (rand > 0.5 ? 1 : -1) }}
				exit={{ opacity: 0 }}
				transition={{ duration: 1, ease: 'circOut' }}>
				<WrapItem height='30vh'>
					<Image
						onClick={onOpen}
						src={content}
						borderRadius='20px'
						borderColor={'#ffffff'}
						borderWidth='4px'
						borderStyle={'solid'}
						height='100%'
						fit='contain'
						align='center center'
						fallbackSrc={fallback}
					/>
					{/* </motion.div> */}
					{/* <img src={content} style={styles.image}></img> */}
				</WrapItem>
			</motion.div>
		</WrapItem>
	);
}
const styles = {
	image: {
		borderRadius: '20px',
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		maxWidth: '100%',
		maxHeight: '100%',
	},
};
